import { GetStaticPropsContext } from 'next';
import { UrlObject } from 'url';

import { ExperimentApplied } from 'ampli-types';
import Cookies from 'js-cookie';
import { REMARKABLE_COUNTRY_COOKIE } from 'src/constants';
import { tracker } from 'src/services/tracker';
import EXPERIMENTS from './experiments.json';

const experiments = EXPERIMENTS as Experiment[];

export enum ExperimentVariant {
  CG = 'CG',
  A = 'A',
  B = 'B',
  C = 'C',
  D = 'D',
  E = 'E',
}

type Variant = {
  name: string;
  id: string;
  weight: number;
};

export type Experiment = {
  name: string;
  id: string;
  pageUrl: `/${string}`;
  /** List of countries the experiment applies to. Uses the visitors origin country.
   All countries are included if this is undefined */
  participatingCountries?: string[];
  variants: Variant[];
};

/**
 * Returns the experiment to use, the overall flow is:
 * - You created an experiment in Google Optimize
 * - Then created the pages that will match that experiment, in this case pages/[variant]
 * - Start experimenting and then make decisions without having changed the original pages
 */
export const getExperimentById = (id: string): Experiment | undefined =>
  experiments.find((experiment) => experiment.id === id);

export const getExperimentByUrl = (url: string): Experiment | undefined =>
  experiments.find((exp) => exp.pageUrl === url);

export const getAllExperiments = () => experiments;

export const getExperimentCookie = ({ variants, id }: Experiment): string => {
  let n = Math.random() * 100;
  const variant = variants.find((v) => {
    if (v.weight >= n) return true;
    n -= v.weight;
  });
  return `${id}.${variant?.id ?? 'CG'}`;
};

export type ExperimentPath = {
  params: {
    variant: `${string}.${string}` | string[];
  };
};

const getVariantFreeQuery = (query: UrlObject['query']): UrlObject['query'] => {
  if (query && typeof query !== 'string') {
    const { variant: _, ...rest } = query;
    return rest;
  }
  return query;
};

/**
 * For masking urls in the browser when performing client side url replacements on the same page.
 *
 * Example usage:
 * ```ts
 * router.replace({query}, getExperimentAsUrl({pathname, query}));
 * ```
 */
export const getExperimentAsUrl = ({ pathname, query, ...rest }: UrlObject): UrlObject => {
  return {
    pathname: pathname?.replace('/[variant]', '') ?? null,
    query: getVariantFreeQuery(query),
    ...rest,
  };
};

/**
 * Generates a path for each group/variant in the experiment. Requires the page
 * that uses it to be wrapped in brackets. E.g [variant].tsx
 *
 * @param experimentId id-field of the experiment
 */
export const getStaticPathsExperiments = (experimentId: string): ExperimentPath[] => {
  const experiment = getExperimentById(experimentId);
  if (!experiment) return [];
  return experiment.variants.map((variant) => ({
    params: { variant: `${experiment.id}.${variant.id}` },
  }));
};

/**
 * Generates each path for the experiment, and includes the base-path. Used when
 * some visitors are excluded from the experiment, for instance if the experiment is
 * country specific. Requires the name of the page that uses it to be wrapped in brackets and
 * three dots. E.g: [...variant].tsx
 *
 * @param experimentId id-field of the experiment
 */
export const createCatchAllStaticPathsExperiments = (experimentId: string): ExperimentPath[] => {
  const experiment = getExperimentById(experimentId);
  if (!experiment) return [];
  return [
    ...experiment.variants.map((variant) => ({
      params: { variant: [`${experiment.id}.${variant.id}`] },
    })),
    { params: { variant: [] } },
  ];
};

export type ExperimentProps = {
  experimentId: string;
  experimentVariantId: ExperimentVariant;
};

export const getStaticPropsExperiments = ({ params }: GetStaticPropsContext): ExperimentProps => {
  if (!params) return { experimentId: '', experimentVariantId: ExperimentVariant.CG };
  const [experimentId, variantId] = typeof params.variant === 'string' ? params.variant.split('.') : ['', ''];
  if (Object.values<string>(ExperimentVariant).includes(variantId)) {
    return { experimentId, experimentVariantId: ExperimentVariant[variantId] };
  }
  return { experimentId, experimentVariantId: ExperimentVariant.CG };
};

export const setExperimentCookieIfApplicable = (id: string) => {
  const experiment = getExperimentById(id);
  const country = Cookies.get(REMARKABLE_COUNTRY_COOKIE);
  if (
    experiment &&
    country &&
    (!experiment.participatingCountries || experiment.participatingCountries.includes(country))
  ) {
    const cookie = Cookies.get(experiment.id);
    if (!cookie) {
      Cookies.set(experiment.id, getExperimentCookie(experiment));
    }
  }
};

/** TODO: We should get this cookie from the tracking-browser api */
const findExperimentInTrackingCookie = (id: string) => {
  const cookie = Cookies.get('rmMetaV2-experiments');
  if (cookie) {
    const parsed = JSON.parse(cookie);
    return parsed.find((experiment) => experiment.id === id);
  }
};

export const applyExperimentTracking = (id: string) => {
  const experiment = getExperimentById(id);
  const country = Cookies.get(REMARKABLE_COUNTRY_COOKIE);
  if (
    experiment &&
    country &&
    !findExperimentInTrackingCookie(id) &&
    (!experiment.participatingCountries || experiment.participatingCountries.includes(country))
  ) {
    const cookie = Cookies.get(experiment.id);
    const experimentData = {
      name: (EXPERIMENTS as Experiment[]).find((e) => e.id === experiment.id)?.name ?? 'undefined-experiment',
      id: experiment.id,
      variant: cookie?.split('.')[1] ?? '',
      country: Cookies.get(REMARKABLE_COUNTRY_COOKIE),
    };
    tracker.setExperiment(experimentData);
    tracker.trackEvent(
      new ExperimentApplied({
        experiment_id: experimentData.id,
        experiment_variant: experimentData.variant,
      })
    );
  }
};
