import { Typography } from '@remarkable/ark-web';
import React from 'react';

type QuoteProps = {
  text: string;
};

const Quote: React.FunctionComponent<QuoteProps> = ({ text }) => (
  <li key={text} className={'flex min-h-[100px] items-center justify-center'}>
    <Typography variant="body-md-book-italic" className="mt-0 font-normal text-16 lg:text-20 max-w-[600px]">
      {text}
    </Typography>
  </li>
);

export default Quote;
