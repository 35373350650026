import { getSanityImageResponsive, Image, Typography } from '@remarkable/ark-web';
import React from 'react';
import HandDrawnMarkup from 'src/components/HandDrawnMarkup';
import { extractGenericImageBySelector, getParagraphTextFromWebsiteContent } from 'src/helpers/sanityHelpers';
import { BP } from 'src/helpers/tailwindBreakpointsHelpers';
import { useActiveBreakpoint } from 'src/hooks/useActiveBreakpoint';
import { ExperimentVariant } from 'src/services/experiments/optimize';
import { WebsiteSectionContentWithImages, WebsiteSectionContentWithTexts } from 'src/typings/websiteContent';
import { isDesktop } from 'src/utils/isDesktop';
import sanityClient from 'src/utils/sanityClient';

interface Props {
  sectionContent: WebsiteSectionContentWithImages & WebsiteSectionContentWithTexts;
  experimentVariantId: ExperimentVariant;
}

const showOrganizedTextUnderlined = (isDesktop = true) => {
  const scale = { x: isDesktop ? 1.1 : 1.15, y: isDesktop ? 1 : 1 };
  const translation = isDesktop ? { y: 100, x: 6 } : { y: 115, x: 8 };
  return (
    <div className="inline">
      <HandDrawnMarkup scale={scale} invert variant="Underline_4" color="black" translation={translation}>
        organized
      </HandDrawnMarkup>
    </div>
  );
};

const OrganizedSection: React.FC<Props> = ({ sectionContent, experimentVariantId }: Props) => {
  const paragraph = getParagraphTextFromWebsiteContent(sectionContent, 'organized-paragraph');
  const image = extractGenericImageBySelector(sectionContent, 'organized-image');
  const currentBp: BP | null = useActiveBreakpoint();

  const HandDrawnUnderline = () => showOrganizedTextUnderlined(isDesktop(currentBp));
  return (
    <div className="flex flex-col w-full max-w-[624px]">
      {experimentVariantId === ExperimentVariant.A ? (
        <Image className="w-full aspect-[1.48] max-h-[420px]" src="/images/DoYourBestWork.jpeg" alt="Handwriting" />
      ) : (
        <Image
          className="w-full"
          {...getSanityImageResponsive({
            sanityClient: sanityClient,
            sanityImage: image,
            alt: image.alt ?? '',
            options: { width: 800 },
          })}
        />
      )}
      <div className="mt-20 lg:mt-28 text-left basis-1/2 max-w-[624px]">
        {experimentVariantId === ExperimentVariant.A ? (
          <div className="heading-sm lg:text-42">Do your best work</div>
        ) : (
          <div className="heading-sm lg:text-42">
            All your work, <HandDrawnUnderline />
          </div>
        )}

        <Typography variant="body-md-book" className="mt-8 md:text-16 lg:text-18">
          {experimentVariantId === ExperimentVariant.A
            ? 'reMarkable has no pop-ads or notifications to interrupt your flow, so you can find the focus you need to think clearly and do your best work.'
            : paragraph}
        </Typography>
      </div>
    </div>
  );
};

export default OrganizedSection;
