import { useState } from 'react';

import TabItem from './TabItem';
interface Props {
  children: JSX.Element[];
  defaultLabel?: string;
}
interface TabProps {
  children: React.ReactNode;
  label: string;
  color?: string;
  icon?: { component: React.FunctionComponent<React.SVGProps<SVGSVGElement>>; ariaLabelledby: string };
}

export const Tab = (props: TabProps) => <div {...props}></div>;

export const Tabs = (props: Props) => {
  const { children: tabs, defaultLabel } = props;
  const [activeTab, setActiveTab] = useState(defaultLabel ?? tabs[0].props.label);

  function onClickTabItem(tabLabel: string) {
    setActiveTab(tabLabel);
  }

  const tabsJSX = tabs.map((tab) => {
    const { label, icon } = tab.props;
    return <TabItem activeTab={activeTab} key={label} label={label} onTabClick={onClickTabItem} icon={icon} />;
  });

  const activeTabJSX = tabs.find((tab) => tab.props.label === activeTab)?.props.children;

  return (
    <>
      <ul className="flex justify-around w-full p-0 m-0 list-none bg-gray-900 text-grayscale-gray-50">{tabsJSX}</ul>
      <div className="bg-black">{activeTabJSX}</div>
    </>
  );
};
