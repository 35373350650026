import { Typography } from '@remarkable/ark-web';
import React from 'react';
import Icon from 'src/components/Icon';
import { getParagraphTextFromWebsiteContent } from 'src/helpers/sanityHelpers';
import { useExperimentContext } from 'src/services/experiments/ExperimentContext';
import { ExperimentVariant } from 'src/services/experiments/optimize';
import { WebsiteSectionContentWithTexts } from 'src/typings/websiteContent';

interface Props {
  icon: { component: React.FunctionComponent<React.SVGProps<SVGSVGElement>>; ariaLabelledby: string };
  sectionContent: WebsiteSectionContentWithTexts;
  index: number;
}

const PerkItem: React.FC<Props> = ({ icon, sectionContent, index }: Props) => {
  const { experimentVariantId } = useExperimentContext();
  if (!sectionContent.Texts) {
    return null;
  }

  const header = getParagraphTextFromWebsiteContent(sectionContent, `perk${index}Header`);
  const description =
    experimentVariantId === ExperimentVariant.A
      ? index === 1
        ? 'Paper-thin design for comfortable handwriting and typing.'
        : 'Replace your notebooks with a device thin enough to take anywhere.'
      : getParagraphTextFromWebsiteContent(sectionContent, `perk${index}Description`);

  return (
    <div className={'flex flex-col items-center justify-center mt-0 md:mr-[50px] mb-[50px] w-[265px] text-center'}>
      <Icon component={icon.component} ariaLabelledby={icon.ariaLabelledby} size={32} fill={'#EFEFEF'} />

      <Typography variant="body-md-bold" className="mt-20 mr-0 mb-12 text-grayscale-gray-50">
        {header}
      </Typography>

      <Typography variant="body-sm-book" className="text-grayscale-gray-50">
        {description}
      </Typography>
    </div>
  );
};

export default PerkItem;
