import { CarouselCanvas, useGallery } from '@remarkable/ark-web';
import { ButtonClicked } from 'ampli-types';
import { debounce } from 'lodash';
import Link from 'next/link';
import { ArrowCircleUpRight } from 'phosphor-react';
import React, { useEffect, useMemo, useState } from 'react';
import { useInterval } from 'src/helpers/customHooks';
import { BP } from 'src/helpers/tailwindBreakpointsHelpers';
import { useActiveBreakpoint } from 'src/hooks/useActiveBreakpoint';
import { PressPublicationsData } from 'src/queries/groq/publicationsData';
import { tracker } from 'src/services/tracker';
import { ButtonTypes, ComponentLocations } from 'src/services/tracking/eventTypes';
import PressLogos from './PressLogos';
import Quote from './Quote';

type Props = {
  width?: number;
  pressReviews: PressPublicationsData;
};

const Carousel: React.FunctionComponent<Props> = ({ pressReviews }) => {
  const currentBp: BP | null = useActiveBreakpoint();
  const [isRunning, setIsRunning] = useState(true);
  const { canvasController, index, onIndex } = useGallery({
    count: pressReviews.length,
  });

  //Scroll to correct logo
  const horizontalScrollToLogo = (newIndex: number) => {
    const elem: HTMLElement | null = document.getElementById('pressLogosList');
    if (elem) {
      elem.scrollTo({
        top: 0,
        left: 163 * newIndex, //140 = width of each logo in px
        behavior: 'smooth',
      });
    }
    return true;
  };

  //Make sure we only have one instance of the scroll function if user scrolls fast
  const debouncedScroll = useMemo(() => debounce(horizontalScrollToLogo, 150), []);

  //Auto scroll to next quote after 5 sec
  useInterval(
    () => {
      const newIndex = (index + 1) % pressReviews.length;
      onIndex(newIndex);
      newIndex !== 0 && debouncedScroll(newIndex);
    },
    isRunning ? 5000 : null
  );

  useEffect(() => {
    debouncedScroll(index);
  }, [index, debouncedScroll]);

  //Scroll to middle logo on mobile
  useEffect(() => {
    if (currentBp === 'xs' || currentBp === 'sm') {
      const middleIndex = Math.round((pressReviews.length - 1) / 2);
      debouncedScroll(middleIndex);
    }
  }, [debouncedScroll, pressReviews.length, currentBp]);

  return (
    <span className="sm:mb-16 -mx-24 px-8 max-w-full lg:max-w-[1080px] w-full flex flex-col items-center">
      <PressLogos
        pressReviews={pressReviews}
        index={index}
        onIndex={onIndex}
        setIsRunning={setIsRunning}
        isRunning={isRunning}
        currentBp={currentBp}
      />
      <div className="flex flex-row items-center w-full my-[40px]">
        <CarouselCanvas
          control={canvasController}
          className="[&::-webkit-scrollbar]:hidden [-ms-overflow-style:'none'] [scrollbar-width:'none']"
        >
          {pressReviews.map((item) => (
            <Quote key={item.publicationName + 'Quote'} text={item.quotes ? '"' + item.quotes[0].quote + '"' : '""'} />
          ))}
        </CarouselCanvas>
      </div>
      <Link
        className="w-[155px] flex flex-row items-center justify-between no-underline"
        href={pressReviews[index].quotes[0].articleURL}
        target="_blank"
        onClick={() =>
          tracker.trackEvent(
            new ButtonClicked({
              button_type: ButtonTypes.NAVIGATION.EXTERNAL_LINK,
              text: pressReviews[index].publicationName,
              component_location: ComponentLocations.FRONT_PAGE.PRESS_MODULE,
              link_source: pressReviews[index].quotes[0].articleURL,
            })
          )
        }
      >
        <div className="text-16">Read full article</div>
        <ArrowCircleUpRight className="text-brand-sand-600" size={32} weight="fill" />
      </Link>
    </span>
  );
};

export default Carousel;
