import { getSanityImageResponsive, Image, Typography } from '@remarkable/ark-web';
import React from 'react';
import Brightness from 'src/assets/Icons/icon_specific_brightness_32.svg';
import DragDrop from 'src/assets/Icons/icon_specific_dragdrop_32.svg';
import PDF from 'src/assets/Icons/icon_specific_pdf_32.svg';
import Icon from 'src/components/Icon';

import {
  extractGenericImageBySelector,
  getParagraphTextFromWebsiteContent,
  getParagraphTextListFromWebsiteContent,
} from 'src/helpers/sanityHelpers';
import { WebsiteSectionContentWithImages, WebsiteSectionContentWithTexts } from 'src/typings/websiteContent';
import sanityClient from 'src/utils/sanityClient';

interface Props {
  sectionContent: WebsiteSectionContentWithTexts & WebsiteSectionContentWithImages;
}

const bulletIcons = [
  <Icon component={DragDrop} ariaLabelledby="drag and drop" size={32} />,
  <Icon component={Brightness} ariaLabelledby="brightness" size={32} />,
  <Icon component={PDF} ariaLabelledby="pdf" size={32} />,
];

export const NotesOnDocument: React.FC<Props> = ({ sectionContent }) => {
  const header = getParagraphTextFromWebsiteContent(sectionContent, 'section1-header');
  const paragraph = getParagraphTextFromWebsiteContent(sectionContent, 'section1-paragraph');
  const image = extractGenericImageBySelector(sectionContent, 'image2');
  const bullets = getParagraphTextListFromWebsiteContent(sectionContent, 'section1-bullet');

  return (
    <div className="flex flex-col mt-32 md:mt-48 lg:grid lg:grid-cols-[1fr_1fr] lg:gap-x-32 items-center [&>picture]:w-full">
      <Image
        className="w-full lg:order-last h-[220px] sm:h-[420px] md:h-max lg:h-[460px] object-cover"
        {...getSanityImageResponsive({
          sanityClient: sanityClient,
          sanityImage: image,
          alt: image.alt ?? '',
          options: {
            width: 426,
          },
          sources: [
            {
              media: '(min-width: 1024px)',
              options: { width: 680 },
            },
            {
              media: '(min-width: 768px)',
              options: { width: 814 },
            },
            {
              media: '(min-width: 475px)',
              options: { width: 606 },
            },
          ],
        })}
      />

      <div className="w-full text-left lg:order-first lg:px-24">
        <Typography as="h3" variant="responsive-heading-lg" className="p-0 pt-24 m-0 font-serif md:pt-32 max-w-[526px]">
          {header}
        </Typography>

        <Typography variant="responsive-body-md" className="mt-8 lg:mt-24 mb-32 max-w-[600px]">
          {paragraph}
        </Typography>

        <div className="flex flex-col mb-40 lg:mb-0 lg:px-0 lg:space-x-0">
          {bullets.map((bullet, index) => {
            return (
              <div className={`flex flex-row items-center ${index >= 1 ? 'mt-12' : 'mt-4'}`} key={`_${index}`}>
                {bulletIcons[index]}
                <Typography variant="body-md-book" className="m-0 ml-16">
                  {bullet}
                </Typography>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};
