import { getSanityImage, Image, Typography } from '@remarkable/ark-web';
import React from 'react';
import { extractGenericImageBySelector } from 'src/helpers/sanityHelpers';
import { WebsiteSectionContentWithImages, WebsiteSectionContentWithTexts } from 'src/typings/websiteContent';
import sanityClient from 'src/utils/sanityClient';

interface Props {
  sectionContent: WebsiteSectionContentWithTexts & WebsiteSectionContentWithImages;
}

const IntegrationSection: React.FC<Props> = ({ sectionContent }) => {
  if (!sectionContent) return null;

  const googleDriveIcon = extractGenericImageBySelector(sectionContent, 'integrations-google-drive');
  const dropboxIcon = extractGenericImageBySelector(sectionContent, 'integrations-dropbox');
  const oneDriveIcon = extractGenericImageBySelector(sectionContent, 'integrations-onedrive');

  return (
    <div className="flex flex-col items-center w-full px-48 py-40 bg-brand-sand-300 md:py-[72px]">
      <Typography variant="body-xl-book" className="text-center md:text-24">
        Access, sync, and store files with cloud storage services
      </Typography>
      <div className="flex max-w-[500px] pt-24 md:pt-40 text-center">
        <div className="flex flex-col items-center mx-16 md:mx-[28px]">
          <Image
            className="w-56"
            {...getSanityImage({
              sanityClient: sanityClient,
              sanityImage: googleDriveIcon,
              alt: googleDriveIcon.alt ?? '',
              options: { width: 56 },
            })}
          />
          <Typography variant="body-sm-book" className="mt-4">
            Google Drive
          </Typography>
        </div>
        <div className="flex flex-col items-center mx-16 md:mx-[28px] text-center">
          <Image
            className="w-56"
            {...getSanityImage({
              sanityClient: sanityClient,
              sanityImage: oneDriveIcon,
              alt: oneDriveIcon.alt ?? '',
              options: { width: 56 },
            })}
          />
          <Typography variant="body-sm-book" className="mt-4">
            Microsoft OneDrive
          </Typography>
        </div>
        <div className="flex flex-col items-center mx-16 md:mx-[28px] text-center">
          <Image
            className="w-56"
            {...getSanityImage({
              sanityClient: sanityClient,
              sanityImage: dropboxIcon,
              alt: dropboxIcon.alt ?? '',
              options: { width: 56 },
            })}
          />
          <Typography variant="body-sm-book" className="mt-4">
            Dropbox
          </Typography>
        </div>
      </div>
    </div>
  );
};

export default IntegrationSection;
