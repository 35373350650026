import { getSanityImageSrc, Image, Typography } from '@remarkable/ark-web';
import cx from 'classnames';
import { useRef } from 'react';
import VideoPlayer, { PlayButtonTextOverlay } from 'src/components/Video/VideoPlayer';
import { extractGenericImageBySelector, getParagraphTextFromWebsiteContent } from 'src/helpers/sanityHelpers';
import { BP } from 'src/helpers/tailwindBreakpointsHelpers';
import { useActiveBreakpoint } from 'src/hooks/useActiveBreakpoint';
import { useExperimentContext } from 'src/services/experiments/ExperimentContext';
import { ExperimentVariant } from 'src/services/experiments/optimize';
import { ComponentLocations } from 'src/services/tracking/eventTypes';
import { WebsiteSectionContentWithImages, WebsiteSectionContentWithTexts } from 'src/typings/websiteContent';
import { client } from 'src/utils/sanityClient';
interface Props {
  sectionContent: WebsiteSectionContentWithTexts & WebsiteSectionContentWithImages;
}

export const TypeFolioSummary = ({ sectionContent }: Props): JSX.Element => {
  const { images, header } = sectionContent;
  const imagesRef = useRef(images);

  const currentBp: BP | null = useActiveBreakpoint();
  const extractedGenericImageBySelector = extractGenericImageBySelector(sectionContent, 'videoPosterImage-summary2023');

  const getTypeFolioSummaryPosterImageWidth = (bp: BP | null): number => {
    if (bp === 'lg' || bp === 'xl') return 1200;
    if (bp === 'md') return 900;
    if (bp === 'sm') return 750;
    if (bp === 'xs') return 450;
    return 200;
  };

  const videoPosterImage = getSanityImageSrc({
    sanityClient: client,
    sanityImage: extractedGenericImageBySelector,
    options: { width: getTypeFolioSummaryPosterImageWidth(currentBp), quality: 75 },
  });

  const videoUrl = getParagraphTextFromWebsiteContent(sectionContent, 'videoUrl-summary2023') || '';

  const { experimentVariantId } = useExperimentContext();

  const experimentLabels = [
    'Paper-like writing and reading',
    'Convert your handwriting to typed text',
    'View, sync, and refine using our apps',
    'All your work, organized and in one place',
    '2 weeks \n of battery life',
  ];
  if (experimentVariantId === ExperimentVariant.A) {
    imagesRef.current = imagesRef.current.filter((el) => el.selector === 'experimentSummaryIcon');
    for (let x = 0; x < imagesRef.current.length; x++) {
      imagesRef.current[x].caption = experimentLabels[x];
    }
  }

  return (
    <section
      data-theme="on-dark"
      className={cx('pb-32 md:pb-[120px] bg-brand-green-600 ', 'grid grid-cols-1')}
      id="Summary"
    >
      <div className="bg-[#F2EEEB] row-start-1 col-start-1 h-40 md:h-[80px]" />
      <VideoPlayer
        width="850px"
        Overlay={PlayButtonTextOverlay(
          experimentVariantId === ExperimentVariant.A ? 'Get to know reMarkable' : 'See how reMarkable works'
        )}
        className="col-start-1 row-span-2 row-start-1 px-24 aspect-video md:px-12"
        loop={false}
        url={videoUrl}
        poster={videoPosterImage}
        trackingOptions={{
          title: 'brandVideo',
          componentLocation: ComponentLocations.FRONT_PAGE.MODULE,
        }}
      />

      <div className="px-24">
        <Typography
          as="h2"
          variant="responsive-heading-md"
          className="m-0 mt-40 md:mt-[96px] mb-[36px] text-center md:mb-64"
        >
          {experimentVariantId === ExperimentVariant.A ? 'reMarkable at a glance' : header}
        </Typography>
      </div>
      <div className="px-24">
        <ul className="grid grid-cols-2 md:grid-cols-5 gap-40 md:max-w-[1100px] md:m-auto list-none px-0  ">
          {imagesRef.current.map((image) => {
            if (image.selector === 'experimentSummaryIcon') {
              return (
                <li
                  key={image.caption}
                  // if even number of icons, remove last-of-type:col-span-2 as it centers the last odd element
                  className="grid content-start justify-items-center last-of-type:col-span-2 md:last-of-type:col-span-1"
                >
                  <Image
                    src={image.image.asset.url}
                    alt={image.alt ?? 'icon'}
                    height={40}
                    width={40}
                    className="mb-8"
                  />
                  <p className="text-center max-w-[200px] whitespace-pre-wrap">{image.caption}</p>
                </li>
              );
            }
          })}
        </ul>
      </div>
    </section>
  );
};
