import { Button, getSanityImageResponsive, Image, Typography } from '@remarkable/ark-web';
import { setTrackClick } from '@remarkable/tracking-browser/lib/ampli';
import { Navigate, NavigateProperties } from 'ampli-types';
import cx from 'classnames';
import NextLink from 'next/link';
import { ArrowUpRight, CaretDown } from 'phosphor-react';
import { FC, useState } from 'react';
import { extractGenericImageBySelector, getParagraphTextFromWebsiteContent } from 'src/helpers/sanityHelpers';
import { ComponentLocations } from 'src/services/tracking/eventTypes';
import { WebsiteSectionContentWithImages, WebsiteSectionContentWithTexts } from 'src/typings/websiteContent';
import sanityClient from 'src/utils/sanityClient';

interface TypeFolioTFProps {
  sectionContent: WebsiteSectionContentWithTexts & WebsiteSectionContentWithImages;
}

export const TypeFolioTF: FC<TypeFolioTFProps> = ({ sectionContent }) => {
  const [showAccordion, toggleAccordion] = useState<boolean>(false);

  const { header, subHeader } = sectionContent;
  const interfaceText = getParagraphTextFromWebsiteContent(sectionContent, 'interface-seabirdModule');
  const badgeText = getParagraphTextFromWebsiteContent(sectionContent, 'badge-seabirdModule');
  const titleImage = extractGenericImageBySelector(sectionContent, 'titleImage-seabirdModule');
  const ctaText = getParagraphTextFromWebsiteContent(sectionContent, 'cta-seabirdModule');
  const accordion1Image = extractGenericImageBySelector(sectionContent, 'image-accordion-01-seabirdModule');
  const accordion2Image = extractGenericImageBySelector(sectionContent, 'image-accordion-02-seabirdModule');
  const accordion3Image = extractGenericImageBySelector(sectionContent, 'image-accordion-03-seabirdModule');
  const accordion4Image = extractGenericImageBySelector(sectionContent, 'image-accordion-04-seabirdModule');

  const buyFolioHref = '/store/remarkable-2/type-folio';

  const trackClickDataBuyTypeFolio: NavigateProperties = {
    action: 'open',
    component_location: ComponentLocations.FRONT_PAGE.MODULE,
    text: ctaText,
    type: 'link',
    link_source: buyFolioHref,
  };

  return (
    <section data-theme="on-dark" className="p-20 pt-48 bg-brand-blue-800 md:p-[80px]" id="type-folio">
      <div className="grid md:grid-cols-[1fr_1fr] md:grid-rows-[1fr_auto_1fr] md:mx-auto md:w-full md:max-w-[1024px]">
        <div className="relative mb-32 justify-self-center min-w-[70vw] max-w-[70vw] xs:min-w-0 md:mb-0 md:col-start-2 md:row-span-full md:justify-self-end md:self-center">
          <Image
            {...getSanityImageResponsive({
              sanityClient: sanityClient,
              sanityImage: titleImage,
              alt: titleImage.alt ?? '',
              options: { width: 200 },
            })}
            className="max-h-[380px] md:max-h-[460px] h-auto w-full"
          />
          <div
            data-theme="on-light"
            className="bg-white rounded-full w-[60px] h-[60px] md:w-[72px] md:h-[72px] lg:h-[96px] lg:w-[96px] grid items-center justify-center absolute right-12 sm:right-32 md:right-20 top-[50%] translate-y-[-50%]"
          >
            <Typography as="h3" variant="heading-xs" className="my-0 text-18 md:text-20 lg:text-28">
              {badgeText || 'New'}
            </Typography>
          </div>
        </div>

        <Typography
          data-cy="article-card-title"
          as="h2"
          variant="interface-sm-caps"
          className="mt-0 mb-20 text-center md:text-left md:self-end md:col-start-1"
        >
          {interfaceText}
        </Typography>
        <Typography
          data-cy="article-card-title"
          as="h2"
          variant="responsive-heading-xl"
          className="text-center md:text-left md:max-w-[425px] md:col-start-1 mt-0 mb-20"
        >
          {header}
        </Typography>
        <Typography
          data-cy="article-card-title"
          as="h3"
          variant="responsive-body-md"
          className="text-center mx-auto sm:max-w-[450px] md:mx-0 md:text-left md:max-w-[360px] md:col-start-1 my-0"
        >
          {subHeader}
        </Typography>

        <div
          aria-hidden="true"
          className={cx(
            'transition ease-linear duration-500 hidden md:grid grid-cols-2 col-start-1 col-end-3 gap-x-[200px] top-[56px] relative',
            { 'opacity-100': showAccordion, 'opacity-0': !showAccordion }
          )}
        >
          <hr className="w-full col-start-1 col-end-2 border-onDark-interface-border-muted" />
          <hr className="w-full col-start-2 col-end-3 border-onDark-interface-border-muted" />
        </div>

        <Button
          variant="secondary"
          className="my-32 justify-self-center md:col-start-1 md:col-end-3 md:relative md:mt-32 md:mb-0"
          onClick={() => {
            toggleAccordion(!showAccordion);
          }}
        >
          <div className="flex items-center justify-center">
            <span className="mr-[4px]">View {!showAccordion ? 'more' : 'less'}</span>
            <CaretDown
              className={cx('ml-[4px] transition duration-500', {
                'rotate-180': showAccordion,
                'rotate-0': !showAccordion,
              })}
              size={32}
            />
          </div>
        </Button>

        <div
          className={cx(
            'transition-[max-height] overflow-hidden ease-in duration-500 xs:grid xs:grid-cols-8 gap-8 sm:gap-16 md:gap-24 sm:max-w-[500px] sm:mx-auto md:w-full md:max-w-[initial] md:pb-0 md:col-start-1 md:col-end-3',
            { 'max-h-[1200px]': showAccordion, 'max-h-0': !showAccordion }
          )}
        >
          <div className="bg-brand-blue-700 md:mt-64 min-h-[210px] min-[420px]:min-h-[240px] mx-auto rounded col-span-4 relative overflow-hidden mb-24  md:aspect-[auto] md:h-[320px] lg:h-[360px] lg:col-span-3 xs:mb-0 max-w-[500px] sm:mx-0 md:mb-0 md:mx-0 md:max-w-[initial]">
            <Typography
              as="h3"
              variant="body-sm-bold"
              className="pt-16 px-16 m-0 sm:text-16 md:text-20 md:p-32 max-w-[300px]"
            >
              {getParagraphTextFromWebsiteContent(sectionContent, 'text-accordion-01-seabirdModule')}
            </Typography>
            <Image
              {...getSanityImageResponsive({
                sanityClient: sanityClient,
                sanityImage: accordion1Image,
                alt: accordion1Image.alt ?? '',
                options: { width: 200 },
              })}
              className="w-[90%] ml-[5%] min-[420px]:w-[70%] min-[420px]:ml-[15%] md:w-[80%] xs:absolute xs:bottom-12 sm:bottom-8 md:max-w-[280px] md:ml-0 md:bottom-0 md:left-2/4 md:translate-x-[-50%] lg:max-w-[320px]"
            />
          </div>

          <div className="bg-brand-blue-700 md:mt-64 min-h-[210px]  mx-auto rounded col-span-4 relative overflow-hidden mb-24 aspect-auto  md:aspect-[auto] md:h-[320px] lg:h-[360px] lg:col-span-5 xs:mb-0 max-w-[500px] sm:mx-0 md:mb-0 md:mx-0 md:max-w-[initial]">
            <Typography
              as="h3"
              variant="body-sm-bold"
              className="pt-16 px-16 m-0 sm:text-16 md:text-20 md:p-32 max-w-[280px]"
            >
              {getParagraphTextFromWebsiteContent(sectionContent, 'text-accordion-02-seabirdModule')}
            </Typography>
            <Image
              {...getSanityImageResponsive({
                sanityClient: sanityClient,
                sanityImage: accordion2Image,
                alt: accordion2Image.alt ?? '',
                options: { width: 200 },
              })}
              className="h-auto w-[110%] min-[420px]:w-[100%] right-0 xs:absolute xs:bottom-24 md:bottom-0 lg:w-[90%]"
            />
          </div>

          <div className="bg-brand-blue-700 min-h-[210px] mx-auto rounded col-span-4 relative overflow-hidden mb-24  md:aspect-[auto] md:h-[320px] lg:h-[360px] lg:col-span-5 xs:mb-0 max-w-[500px] sm:mx-0 md:mb-0 md:mx-0 md:max-w-[initial]">
            <Typography
              as="h3"
              variant="body-sm-bold"
              className="pt-16 px-16 m-0 max-w-[210px] sm:text-16 md:text-20 md:p-32 md:max-w-[280px]"
            >
              {getParagraphTextFromWebsiteContent(sectionContent, 'text-accordion-03-seabirdModule')}
            </Typography>
            <Image
              {...getSanityImageResponsive({
                sanityClient: sanityClient,
                sanityImage: accordion3Image,
                alt: accordion3Image.alt ?? '',
                options: { width: 200 },
              })}
              className="h-auto w-[85%] block mx-auto xs:mt-40 sm:mt-56 mb-20 md:mt-[-6%] lg:mt-0 lg:w-[80%] lg:absolute lg:left-2/4 lg:translate-y-[-45%] lg:top-2/4 lg:translate-x-[-50%]"
            />
          </div>

          <div className="bg-brand-blue-700 min-h-[210px]  mx-auto rounded col-span-4 relative overflow-hidden mb-24  md:aspect-[auto] md:h-[320px] lg:h-[360px] lg:col-span-3 xs:mb-0 max-w-[500px] sm:mx-0 md:mb-0 md:mx-0 md:max-w-[initial]">
            <Typography
              as="h3"
              variant="body-sm-bold"
              className="pt-16 px-16 m-0 sm:text-16 md:text-20 md:p-32 max-w-[280px]"
            >
              {getParagraphTextFromWebsiteContent(sectionContent, 'text-accordion-04-seabirdModule')}
            </Typography>
            <Image
              {...getSanityImageResponsive({
                sanityClient: sanityClient,
                sanityImage: accordion4Image,
                alt: accordion4Image.alt ?? '',
                options: { width: 200 },
              })}
              className="h-auto w-[90%] block mx-auto mb-12 mt-20 xs:mt-40 sm:mt-56 md:w-[70%] md:mt-16 lg:mt-0 lg:max-h-[400px] lg:w-[90%] lg:absolute lg:left-2/4 lg:translate-x-[-50%] lg:bottom-[5%]"
            />
          </div>
          <NextLink href={buyFolioHref} passHref legacyBehavior>
            <Button
              as="a"
              data-theme="on-dark"
              variant="secondary"
              className="mx-auto my-32 justify-self-center col-span-full md:mt-32 md:mb-0"
              target="_blank"
              {...setTrackClick(new Navigate(trackClickDataBuyTypeFolio))}
            >
              <span>{ctaText}</span>
              <ArrowUpRight size={32} />
            </Button>
          </NextLink>
        </div>
      </div>
    </section>
  );
};
