import { Plus, X } from 'phosphor-react';
import { useEffect, useState } from 'react';
import AnimateHeight from 'react-animate-height';
import BlackInc from 'src/assets/Icons/icon_specific_blackink_32.svg';
import PaperDisplay from 'src/assets/Icons/icon_specific_paperdisplay_32.svg';
import Pen from 'src/assets/Icons/icon_specific_pen_32.svg';
import Responsive from 'src/assets/Icons/icon_specific_responsive_32.svg';
import { sanityImgObjectFromGenericImage } from 'src/components/Image/SanityImage/sanityImageHelpers';
import SanityResponsiveImage from 'src/components/Image/SanityResponsiveImage';
import SanityBlockComponent from 'src/helper-components/SanityBlockComponent';
import {
  extractImageBySelector,
  extractImageUrlBySelector,
  extractParagraphsBySelector,
} from 'src/helpers/sanityHelpers';
import { WebsiteSectionContentWithImages, WebsiteSectionContentWithTexts } from 'src/typings/websiteContent';

import { Typography } from '@remarkable/ark-web';
import MobileMenu from './MobileMenu';
import TabsPaperFeel from './TabsPaperFeel';
interface Props {
  sectionContent: WebsiteSectionContentWithTexts & WebsiteSectionContentWithImages;
  isDesktop: boolean;
}

function throwOnNull<T>(tabParagraphs: (T | null)[]): T[] {
  if (tabParagraphs.find((_) => _ === null)) {
    throw Error('Tab paragraphs not found in PaperFeel section');
  }
  return tabParagraphs as T[];
}

const PaperFeel = ({ sectionContent, isDesktop }: Props) => {
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    setIsOpen(isDesktop);
  }, [isDesktop]);

  if (sectionContent == null) {
    return null;
  }
  const { header, subHeader } = sectionContent;

  const paragraph1 = extractParagraphsBySelector(sectionContent, 'paragraph1');

  const tabImages = [
    extractImageBySelector(sectionContent, 'tabImage1'),
    extractImageBySelector(sectionContent, 'tabImage2'),
    extractImageBySelector(sectionContent, 'tabImage3'),
    extractImageBySelector(sectionContent, 'tabImage4'),
  ];

  const tabIcons = [
    extractImageUrlBySelector(sectionContent, 'icon1'),
    extractImageUrlBySelector(sectionContent, 'icon2'),
    extractImageUrlBySelector(sectionContent, 'icon3'),
    extractImageUrlBySelector(sectionContent, 'icon4'),
  ];

  const tabParagraphs = throwOnNull([
    extractParagraphsBySelector(sectionContent, 'tabParagraph1'),
    extractParagraphsBySelector(sectionContent, 'tabParagraph2'),
    extractParagraphsBySelector(sectionContent, 'tabParagraph3'),
    extractParagraphsBySelector(sectionContent, 'tabParagraph4'),
  ]);

  const mobileImage = extractImageBySelector(sectionContent, 'tabImage2');
  const desktopImage = extractImageBySelector(sectionContent, 'backgroundImage');

  return (
    <section
      className="relative pb-32 text-grayscale-gray-100 bg-grayscale-gray-800 lg:pb-48 xl:pb-56"
      data-theme="on-dark"
    >
      <div className="relative md:mb-64 md:h-[482px]">
        <div className="hidden md:absolute md:z-0 md:block md:t-0 md:l-0 md:b-0 md:r-0 md:h-[482px] md:w-full">
          <SanityResponsiveImage
            imgDefault={{
              sanityImgObject: { ...sanityImgObjectFromGenericImage(desktopImage) },
              fitMode: 'cover',
            }}
            alt={desktopImage.alt}
          />
        </div>
        <div className="max-w-[1360px] text-left py-56 px-24 lg:pl-[116px] m-auto">
          <Typography as="h2" variant="heading-xs-italic" className="mb-16 md:relative md:z-10 md:max-w-none">
            {subHeader}
          </Typography>
          <Typography as="h3" variant="responsive-heading-xl" className="md:relative md:z-10 mb-16 max-w-[7em]">
            {header}
          </Typography>
          <Typography as="div" variant="responsive-body-md" className="md:relative md:z-10 max-w-[450px]">
            {paragraph1 && <SanityBlockComponent input={paragraph1} />}
          </Typography>
        </div>
      </div>
      <div className="md:hidden">
        <SanityResponsiveImage
          imgDefault={{
            sanityImgObject: { ...sanityImgObjectFromGenericImage(mobileImage) },
            fitMode: 'cover',
          }}
          alt={mobileImage.alt}
        />
      </div>

      <TabsPaperFeel images={tabImages} icons={tabIcons} paragraphs={tabParagraphs} />
      <div className="flex items-center justify-center w-full">
        <button
          className={
            'bg-grayscale-gray-500 h-48 flex items-center my-64 mb-0 mt-32 pl-16 border-none rounded-[25px] md:hidden'
          }
          aria-controls={'expandable'}
          aria-expanded={isOpen}
          onClick={() => {
            setIsOpen(!isOpen);
          }}
        >
          {isOpen ? (
            <>
              <X size={24} color="#efefef" />
              <Typography className="my-0 mx-16" variant="interface-md-book">
                Close
              </Typography>
            </>
          ) : (
            <>
              <Plus size={26} color="#efefef" />
              <Typography className="my-0 mx-16" variant="interface-md-book">
                Learn more about the display
              </Typography>
            </>
          )}
        </button>
      </div>
      <AnimateHeight height={isOpen ? 'auto' : 0} duration={300} role="dialog">
        <MobileMenu
          data={[
            {
              icon: { component: PaperDisplay, ariaLabelledby: 'paperdisplay' },
              text: 'A paper like surface',
              subText: tabParagraphs[0],
            },
            {
              icon: { component: Responsive, ariaLabelledby: 'responsive' },
              text: 'Incredible responsiveness',
              subText: tabParagraphs[1],
            },
            {
              icon: { component: Pen, ariaLabelledby: 'pen' },
              text: 'Minimal pen-to-ink distance',
              subText: tabParagraphs[2],
            },
            {
              icon: { component: BlackInc, ariaLabelledby: 'black ink' },
              text: 'Crisp black ink',
              subText: tabParagraphs[3],
            },
          ]}
        />
      </AnimateHeight>
    </section>
  );
};

export default PaperFeel;
