import { createTechnicalSpecificationsFromBlockContent } from 'src/components/TechnicalSpecificationsList/createTechnicalSpecificationsFromBlockContent';
import MyScriptLogo from 'src/components/TechnicalSpecificationsList/MyScriptLogo';
import { TechnicalSpecificationList } from 'src/components/TechnicalSpecificationsList/TechnicalSpecificationsList';
import { WebsiteSectionContentWithTexts } from 'src/typings/websiteContent';

interface Props {
  sectionContent: WebsiteSectionContentWithTexts;
}

const TechnicalSpecifications = ({ sectionContent }: Props) => {
  if (!sectionContent) {
    return null;
  }

  const technicalSpecifications = createTechnicalSpecificationsFromBlockContent(sectionContent);
  // Add MyScriptLogo to the 'Other' section
  technicalSpecifications
    ?.find((specification) => specification.category === 'Other')
    ?.description.push(<MyScriptLogo key="Handwriting conversion" />);

  if (!technicalSpecifications) {
    return null;
  }

  return (
    <section id="Specifications" className="px-0 py-0 overflow-x-hidden bg-light md:pt-0 md:px-40 md:py-56">
      <div className="bg-grayscale-gray-700 md:pt-[136px] pt-[80px] pb-[136px]">
        <TechnicalSpecificationList headerText={sectionContent.header} specifications={technicalSpecifications} />
      </div>
    </section>
  );
};

export default TechnicalSpecifications;
