import { getSanityImageResponsive, Image, Typography } from '@remarkable/ark-web';
import React from 'react';
import { extractGenericImageBySelector, getParagraphTextFromWebsiteContent } from 'src/helpers/sanityHelpers';
import { useExperimentContext } from 'src/services/experiments/ExperimentContext';
import { ExperimentVariant } from 'src/services/experiments/optimize';
import { WebsiteSectionContentWithImages, WebsiteSectionContentWithTexts } from 'src/typings/websiteContent';
import sanityClient from 'src/utils/sanityClient';

import FocusSection from './components/FocusSection';
import HandwritingConversionSection from './components/HandwritingConversionSection';
import IntegrationSection from './components/IntegrationSection';
import OrganizedSection from './components/OrganizedSection';
import TypeSection from './components/TypeSection';

interface Props {
  sectionContent: WebsiteSectionContentWithTexts & WebsiteSectionContentWithImages;
}

const Workflow: React.FC<Props> = ({ sectionContent }) => {
  const headerParagraph = getParagraphTextFromWebsiteContent(sectionContent, 'headerParagraph');
  const desktopImage = extractGenericImageBySelector(sectionContent, 'desktopImage');
  const mobileImage = extractGenericImageBySelector(sectionContent, 'mobileImage');
  const { experimentVariantId } = useExperimentContext();

  if (!sectionContent) {
    return null;
  }

  return (
    <section data-theme={'on-light'} className="bg-brand-sand-100">
      <div className="flex flex-col items-center w-full text-center bg-gradient-to-b from-brand-sand-200 to-[#C8B9AE]">
        <Typography variant="responsive-heading-xl" className="pt-40 px-24 md:pt-[72px] lg:pt-[120px]">
          {sectionContent.header}
        </Typography>
        <Typography variant="responsive-body-lg" className="mt-16 px-24 md:mt-32 md:max-w-[720px]">
          {experimentVariantId === ExperimentVariant.A
            ? "reMarkable's incredibly low latency and textured surface make for an unprecedented writing experience."
            : headerParagraph}
        </Typography>
        <Image
          className="hidden w-full md:block"
          loading="lazy"
          {...getSanityImageResponsive({
            sanityClient: sanityClient,
            sanityImage: desktopImage,
            alt: desktopImage.alt ?? '',
            options: { width: 1440 },
          })}
        />
        <Image
          className="block w-full md:hidden"
          loading="lazy"
          {...getSanityImageResponsive({
            sanityClient: sanityClient,
            sanityImage: mobileImage,
            alt: mobileImage.alt ?? '',
            options: { width: 768 },
          })}
        />
      </div>
      <div className="flex justify-center w-full">
        <div
          className="
        max-w-[1360px] 
        my-64
        mx-24
        grid
        md:grid-cols-2
        gap-x-24
        gap-y-64
        lg:gap-y-[112px]
        justify-items-center
        "
        >
          <FocusSection experimentVariantId={experimentVariantId} sectionContent={sectionContent} />
          <TypeSection experimentVariantId={experimentVariantId} sectionContent={sectionContent} />
          <HandwritingConversionSection experimentVariantId={experimentVariantId} sectionContent={sectionContent} />
          <OrganizedSection experimentVariantId={experimentVariantId} sectionContent={sectionContent} />
        </div>
      </div>

      <IntegrationSection sectionContent={sectionContent} />
    </section>
  );
};

export default Workflow;
