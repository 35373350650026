import { Button, Typography } from '@remarkable/ark-web';
import Link from 'next/link';
import { CaretCircleRight } from 'phosphor-react';
import { useRef } from 'react';
import SanityResponsiveImage from 'src/components/Image/SanityResponsiveImage';
import { extractImageBySelector, getParagraphTextFromWebsiteContent } from 'src/helpers/sanityHelpers';
import { WebsiteSectionContentWithImages, WebsiteSectionContentWithTexts } from 'src/typings/websiteContent';

interface Props {
  sectionContent: WebsiteSectionContentWithTexts & WebsiteSectionContentWithImages;
  isMobile: boolean;
}

const RemarkableForTeams = ({ isMobile, sectionContent }: Props) => {
  const imageRef = useRef();

  if (!sectionContent) {
    return null;
  }

  const paragraph1 = getParagraphTextFromWebsiteContent(sectionContent, 'paragraph1');
  const paragraph2 = getParagraphTextFromWebsiteContent(sectionContent, 'paragraph2');
  const image = extractImageBySelector(sectionContent, 'image1');
  const greenDarkBgColor = 'bg-[#243539]';
  return (
    <section className={'md:py-56 md:px-0 p-0 bg-grayscale-gray-50'}>
      <div className={`max-w-[1360px] pt-0 px-24 pb-[50px] my-0 mx-auto justify-around ${greenDarkBgColor} xl:p-0`}>
        <div className={`h-[1px] ${greenDarkBgColor}`}></div>

        <div className={'pb-[1px] text-center my-0 mx-auto'}>
          <Typography
            as="h1"
            variant="heading-2xl"
            className="w-full text-[40px] lg:text-[70px] text-center text-grayscale-gray-50 mx-auto md:mx-auto mt-[60px] md:mt-[100px] mb-40 md:mb-[60px]"
          >
            {sectionContent.header}
          </Typography>

          {isMobile ? (
            <>
              {paragraph1 && (
                <Typography
                  variant="body-md-book"
                  className={
                    'text-grayscale-gray-50 max-w-[870px] lg:text-18 w-full text-left mt-0 mx-0 mb-40 md:text-center md:my-40 md:mx-auto'
                  }
                >
                  {paragraph1}
                </Typography>
              )}
              {paragraph2 && (
                <Typography
                  variant="body-md-book"
                  className={
                    'text-grayscale-gray-50 max-w-[870px] lg:text-18 w-full text-left mt-0 mx-0 mb-40 md:text-center md:my-40 md:mx-auto'
                  }
                >
                  {paragraph2}
                </Typography>
              )}

              <Link href="/business" passHref legacyBehavior>
                <Button as="a" size="large" className="mt-[30px] mb-40 mx-auto">
                  {<CaretCircleRight size={30} className="text-grayscale-gray-50" />}
                  <span>Learn more</span>
                </Button>
              </Link>

              <SanityResponsiveImage
                imgDefault={{
                  sanityImgObject: {
                    ...image.image,
                    alt: image.alt,
                  },
                  fitMode: 'cover',
                }}
                alt={image.alt}
                imgStyle={{
                  objectPosition: '50% 80%',
                }}
                imgClassName="h-[265px]"
              />
            </>
          ) : (
            <>
              <div className="relative overflow-hidden md:max-h-[318px] lg:max-h-[490px]" ref={imageRef.current}>
                <div className="relative w-[1128px] my-[10px] mx-auto text-center md:bottom-[358px] lg:bottom-[300px]">
                  <div className="w-full pb-[75%]" />
                  <SanityResponsiveImage
                    imgDefault={{
                      sanityImgObject: {
                        ...image.image,
                        alt: image.alt,
                      },
                      fitMode: 'cover',
                    }}
                    imgStyle={{
                      objectPosition: '50% 80%',
                    }}
                    alt={image.alt}
                    imgClassName="absolute top-0 left-0 w-full h-full"
                  />
                </div>
              </div>

              {paragraph1 && (
                <Typography
                  variant="body-md-book"
                  className={
                    'text-grayscale-gray-50 max-w-[870px] lg:text-18 w-full text-left mt-0 mx-0 mb-40 md:text-center md:my-40 md:mx-auto'
                  }
                >
                  {paragraph1}
                </Typography>
              )}

              {paragraph2 && (
                <Typography
                  variant="body-md-book"
                  className={
                    'text-grayscale-gray-50 max-w-[870px] lg:text-18 w-full text-left mt-0 mx-0 mb-40 md:text-center md:my-40 md:mx-auto'
                  }
                >
                  {paragraph2}
                </Typography>
              )}

              <Link href="/business" passHref legacyBehavior>
                <Button as="a" size="large" className="mt-0 mb-[60px] mx-auto">
                  {<CaretCircleRight size={30} className="text-grayscale-gray-50" />}
                  <span>Learn more about reMarkable for business</span>
                </Button>
              </Link>
            </>
          )}
        </div>
      </div>
    </section>
  );
};

export default RemarkableForTeams;
