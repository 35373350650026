import { getSanityImageResponsive, Image } from '@remarkable/ark-web';
import cx from 'classnames';
import React, { useEffect } from 'react';
import { BP } from 'src/helpers/tailwindBreakpointsHelpers';
import { PressPublicationsData } from 'src/queries/groq/publicationsData';
import sanityClient from 'src/utils/sanityClient';

type Props = {
  pressReviews: PressPublicationsData;
  index: number;
  onIndex: (index: number) => void;
  setIsRunning: (value: React.SetStateAction<boolean>) => void;
  isRunning: boolean;
  currentBp: BP | null;
};

const PressLogos: React.FunctionComponent<Props> = ({ pressReviews, index, onIndex, setIsRunning, currentBp }) => {
  const refs = React.useRef<HTMLLIElement[]>([]);
  const observer = React.useRef<IntersectionObserver>(null);
  const addNode = React.useCallback((node: HTMLLIElement) => refs.current.push(node), []);

  //Logic for handling index update on horizontal list scroll on mobile-------
  useEffect(() => {
    const handler = (entries: IntersectionObserverEntry[]) => {
      entries.forEach((entry) => {
        if (entry.intersectionRatio >= 1) {
          const indexOfElement = Array.prototype.indexOf.call(refs.current, entry.target);
          onIndex(indexOfElement);
        }
      });
    };

    const options = {
      root: null,
      rootMargin: '0px',
      threshold: 1,
    };

    const getObserver = (ref: React.MutableRefObject<IntersectionObserver | null>) => {
      const observer = ref.current;
      if (observer !== null) {
        return observer;
      }
      const newObserver = new IntersectionObserver(handler, options);
      ref.current = newObserver;
      return newObserver;
    };

    if (currentBp === 'xs' || currentBp === 'sm') {
      if (observer.current) observer.current.disconnect();
      const newObserver = getObserver(observer);

      for (const node of refs.current) {
        newObserver.observe(node);
      }
      return () => newObserver.disconnect();
    }
  }, [currentBp, onIndex, pressReviews.length]);

  return (
    <ul
      id="pressLogosList"
      aria-hidden="true"
      className="list-none max-w-full p-0 m-0 flex flex-row md:justify-center md:overflow-hidden overflow-x-scroll snap-x snap-proximity sm:snap-none [&::-webkit-scrollbar]:hidden [-ms-overflow-style:'none'] [scrollbar-width:'none']"
    >
      {pressReviews.map((item, i) => (
        <li
          id={'pressLogo' + i}
          ref={addNode}
          className={cx(
            'h-[36px] min-w-[115px] max-w-[115px] mx-24 pb-8 cursor-pointer border-solid border-0 border-b-medium snap-center flex items-center justify-center first:ml-[calc(50%)] sm:first:ml-24',
            {
              'border-interaction-primary ease-in-out duration-300 opacity-100 self-center': i === index,
              'opacity-40 border-transparent hover:transition-opacity ease-in-out duration-300 sm:hover:opacity-100':
                i !== index,
            }
          )}
          key={item.publicationName + 'Thumbnail'}
        >
          <button
            key={item.publicationName}
            onClick={() => {
              index !== i ? onIndex(i) : null;
              setIsRunning(false);
            }}
            className="h-full w-full flex items-center justify-center"
          >
            <Image
              {...getSanityImageResponsive({
                sanityClient: sanityClient,
                sanityImage: item.logo,
                alt: item.alt ?? '',
                options: { width: 100 },
              })}
              alt={item.alt}
              className="z-20 min-w-[115px] max-w-[115px] w-full object-contain"
            />
          </button>
        </li>
      ))}
      <li className="h-[36px] min-w-[140px] max-w-[140px] mx-4 pb-8 sm:hidden" />
    </ul>
  );
};

export default PressLogos;
