import { Typography } from '@remarkable/ark-web';
import { CaretCircleDown } from 'phosphor-react';
import React from 'react';
import ScrollAnchor from 'src/components/ScrollAnchor';
import { eventActionEnum, eventCategoryEnum, pushGAEventToDataLayer } from 'src/services/googleTagManager';
import { DataTheme } from 'src/views/MainPage';

type Props = {
  dataTheme?: DataTheme;
  animate?: boolean;
  className?: string;
};

const ScrollButton: React.FC<Props> = ({ dataTheme = 'on-dark', className }) => {
  return (
    <div data-theme={dataTheme} className={className}>
      <div className="mr-32 md:inline-block">
        <ScrollAnchor
          targetId="Summary"
          tabIndex={0}
          className="no-underline flex items-center focus:outline-2 focus:outline-brand-orange-500 focus:outline focus:outline-offset-4"
          onClick={() => {
            pushGAEventToDataLayer(
              eventCategoryEnum.MAIN_PAGE_INTERACTION,
              eventActionEnum.ATF,
              'Scroll to learn more'
            );
          }}
        >
          <CaretCircleDown size={30} weight="light" className="mr-8 animate-scroll-icon" />
          <Typography variant="body-md-book" className="md:inline" as="span">
            Scroll to learn more
          </Typography>
        </ScrollAnchor>
      </div>
    </div>
  );
};

export default ScrollButton;
