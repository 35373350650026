import { Button } from '@remarkable/ark-web';
import { setTrackClick } from '@remarkable/tracking-browser/lib/ampli';
import { BuyNowClicked } from 'ampli-types';
import cx from 'classnames';
import NextLink from 'next/link';
import { Handbag } from 'phosphor-react';
import React from 'react';
import { RM110ReferralDiscount } from 'src/helpers/rm110ReferralDiscount';
import { eventActionEnum, eventCategoryEnum, pushGAEventToDataLayer } from 'src/services/googleTagManager';
import { ButtonTypes, ComponentLocations } from 'src/services/tracking/eventTypes';

import { useFormattedLocalizedPrice } from '../../../../../helpers/formattingHelpers';

interface Props {
  referral: RM110ReferralDiscount;
  className?: HTMLButtonElement | string;
}

const HeroCtaButton: React.FC<Props> = ({ referral, className }) => {
  const referralSavingsPriceString = useFormattedLocalizedPrice(referral?.amount ?? 0);
  const href = '/store/remarkable-2';

  const onCtaClicked = () => {
    pushGAEventToDataLayer(eventCategoryEnum.MAIN_PAGE_NAVIGATION, eventActionEnum.CTA, 'Buy now (ATF)');
  };

  const buttonText = `Buy now${referral ? ` - Save ${referralSavingsPriceString}` : ''}`;

  const trackClickData = {
    component_location: ComponentLocations.FRONT_PAGE.HERO,
    link_source: href,
    text: buttonText,
    button_type: ButtonTypes.PURCHASE.BUY_NOW,
  };

  return (
    <NextLink href={href} passHref legacyBehavior>
      <Button
        id="hero-cta-buy-btn"
        as={'a'}
        className={cx(className, { 'mb-32 md:mb-0 md:mr-48': !className })}
        variant="primary"
        data-cy="mainpage-hero-buy-now-btn"
        onClick={() => onCtaClicked()}
        {...setTrackClick(new BuyNowClicked(trackClickData))}
      >
        <Handbag />
        <span>{buttonText}</span>
      </Button>
    </NextLink>
  );
};

export default HeroCtaButton;
