import { getSanityImageResponsive, Image, Typography } from '@remarkable/ark-web';
import React from 'react';
import FormattingIcon from 'src/assets/Icons/Formatting.svg';
import LanguagesIcon from 'src/assets/Icons/Languages.svg';
import MailIcon from 'src/assets/Icons/Mail.svg';
import ImageSlider from 'src/components/Image/ImageSlider';
import {
  extractGenericImageBySelector,
  getParagraphTextFromWebsiteContent,
  getParagraphTextListFromWebsiteContent,
} from 'src/helpers/sanityHelpers';
import { ExperimentVariant } from 'src/services/experiments/optimize';
import { WebsiteSectionContentWithImages, WebsiteSectionContentWithTexts } from 'src/typings/websiteContent';
import client from 'src/utils/sanityClient';

interface Props {
  sectionContent: WebsiteSectionContentWithTexts & WebsiteSectionContentWithImages;
  experimentVariantId: ExperimentVariant;
}

const HandwritingConversionSection: React.FC<Props> = ({ sectionContent, experimentVariantId }) => {
  const header = getParagraphTextFromWebsiteContent(sectionContent, 'hwc-header');
  const paragraph = getParagraphTextFromWebsiteContent(sectionContent, 'hwc-paragraph');
  const bullets = getParagraphTextListFromWebsiteContent(sectionContent, 'hwc-bullet');
  const background = extractGenericImageBySelector(sectionContent, 'background');
  const foreground = extractGenericImageBySelector(sectionContent, 'foreground');

  const imageStyle = 'overflow-hidden w-full max-h-[420px] aspect-[1.48]';

  const renderIcon = (index: number) => {
    switch (index) {
      case 0:
        return <LanguagesIcon width={24} height={24} fill="#222222" />;
      case 1:
        return <FormattingIcon width={24} height={24} fill="#222222" />;
      case 2:
        return <MailIcon width={24} height={24} fill="#222222" />;
    }
  };

  const imageSlideSource = [
    {
      media: '(min-width: 1023px)',
      options: { width: 700 },
    },
    {
      media: '(min-width: 767px)',
      options: { width: 500 },
    },
    {
      media: '(min-width: 474px)',
      options: { width: 700 },
    },
    {
      media: '(min-width: 374px)',
      options: { width: 400 },
    },
  ];

  const foregroundImage =
    experimentVariantId === ExperimentVariant.A ? (
      <Image className="h-full" src="/images/NoMoreTypingUpNotes1.jpeg" alt="Handwriting" />
    ) : (
      <Image
        className="h-full"
        {...getSanityImageResponsive({
          alt: foreground.alt ?? '',
          sanityClient: client,
          sanityImage: foreground,
          options: { width: 400 },
          sources: imageSlideSource,
        })}
      />
    );

  const backgroundImage =
    experimentVariantId === ExperimentVariant.A ? (
      <Image className="h-full" src="/images/NoMoreTypingUpNotes2.jpeg" alt="Handwriting" />
    ) : (
      <Image
        className="h-full"
        {...getSanityImageResponsive({
          alt: foreground.alt ?? '',
          sanityClient: client,
          sanityImage: background,
          options: { width: 400 },
          sources: imageSlideSource,
        })}
      />
    );

  return (
    <div className="flex flex-col w-full max-w-[624px]">
      <div className="basis-1/2">
        <ImageSlider
          sliderColor="bg-dark border-dark"
          imgSizeStyle={imageStyle}
          foregroundImage={foregroundImage}
          backgroundImage={backgroundImage}
        />
      </div>
      <div className="mt-20 lg:mt-28 text-left basis-1/2 md:max-w-[624px]">
        <Typography variant="heading-sm" className="lg:text-42 md:mt-0">
          {experimentVariantId === ExperimentVariant.A ? 'No more typing up notes' : header}
        </Typography>
        <div className="text-left md:max-w-[512px]">
          <Typography variant="body-md-book" className="mt-8 mb-24 md:text-16 lg:text-18">
            {experimentVariantId === ExperimentVariant.A
              ? 'Easily convert handwritten notes to typed text. Move your converted notes into other notebooks or refine, expand, and quickly share them with others.'
              : paragraph}
          </Typography>
          <div className="flex flex-col text-left lg:mt-16 text-grayscale-gray-50">
            {bullets.map((bullet, index) => {
              return (
                <div className="flex flex-row items-center justify-start mt-8" key={index}>
                  {renderIcon(index)}
                  <Typography variant="body-md-book" className="ml-16 text-14 md:text-16">
                    {bullet}
                  </Typography>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default HandwritingConversionSection;
