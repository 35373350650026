import { Plus, X } from 'phosphor-react';
import { useState } from 'react';
import AnimateHeight from 'react-animate-height';
import ArrowsOut from 'src/assets/Icons/icon_arrows_out_32.svg';
import Copy from 'src/assets/Icons/icon_copy_32.svg';
import Erase from 'src/assets/Icons/icon_erase_32.svg';
import Note from 'src/assets/Icons/icon_note_32.svg';
import PaintBrush from 'src/assets/Icons/icon_paint_brush_32.svg';
import Stack from 'src/assets/Icons/icon_stack_32.svg';
import Undo from 'src/assets/Icons/icon_undo_32.svg';
import { getParagraphTextFromWebsiteContent } from 'src/helpers/sanityHelpers';
import { BP } from 'src/helpers/tailwindBreakpointsHelpers';
import { useActiveBreakpoint } from 'src/hooks/useActiveBreakpoint';
import { WebsiteSectionContentWithImages, WebsiteSectionContentWithTexts } from 'src/typings/websiteContent';
import { isMobile } from 'src/utils/isMobile';
import { isTablet } from 'src/utils/isTablet';
import Video from 'src/views/MainPage/components/Video';
import ImageCarousel from '../../components/ImageCarousel';
import { IconsListType } from '../../Typings';
import Icons from './components/Icons';

import { Typography } from '@remarkable/ark-web';
import { useExperimentContext } from 'src/services/experiments/ExperimentContext';
import { ExperimentVariant } from 'src/services/experiments/optimize';
import MobileExpandableMenu from './components/MobileExpandableMenu';

interface Props {
  sectionContent: WebsiteSectionContentWithTexts & WebsiteSectionContentWithImages;
}

const data: IconsListType[] = [
  { icon: { component: Note, ariaLabelledby: 'note' }, text: 'Templates' },
  { icon: { component: ArrowsOut, ariaLabelledby: 'arrows_out' }, text: 'Resize and move' },
  { icon: { component: PaintBrush, ariaLabelledby: 'paint_brush' }, text: 'Brushes' },
  { icon: { component: Undo, ariaLabelledby: 'undo' }, text: 'Undo' },
  { icon: { component: Erase, ariaLabelledby: 'erase' }, text: 'Erase' },
  { icon: { component: Copy, ariaLabelledby: 'copy' }, text: 'Copy and paste' },
  { icon: { component: Stack, ariaLabelledby: 'stack' }, text: 'Layers' },
];

function Visualization({ sectionContent }: Props) {
  const [isOpen, setIsOpen] = useState(false);
  const currentBp: BP | null = useActiveBreakpoint();
  const isMobileOrTablet = isMobile(currentBp) || isTablet(currentBp);
  const { experimentVariantId } = useExperimentContext();

  if (sectionContent == null) {
    return null;
  }
  const { images } = sectionContent;

  const carouselImages = images.filter((image) => image?.selector?.startsWith('carousel'));
  const paragraph = getParagraphTextFromWebsiteContent(sectionContent, 'paragraph1');

  return (
    <section className={'overflow-x-hidden bg-brand-blue-500 text-grayscale-gray-50'}>
      <div
        className={
          'flex flex-col pt-[75px] pb-48 lg:grid lg:grid-cols-2 lg:items-center lg:gap-x-32 lg:gap-y-56 lg:px-[48px] lg:pl-40 lg:pr-40 lg:pb-128 xl:m-auto xl:max-w-[1500px] xl:grid-cols-[1fr_4fr_6fr_1fr] xl:px-0 [@media(min-width:1920px)]:grid-cols-[2fr_6fr]'
        }
      >
        <div
          data-cy="visualization-video-container"
          className={
            'py-0 px-24 md:px-[100px] md:py-24 lg:p-0 xl:col-start-2 xl:col-end-2 [@media(min-width:1920px)]:col-start-1 [@media(min-width:1920px)]:col-end-1'
          }
        >
          <Typography
            as="h1"
            variant="heading-2xl"
            className="relative mx-auto mb-64 max-w-[400px] text-center text-[40px] text-grayscale-gray-50 before:absolute before:bottom-[-30%] before:left-[9%] before:w-[80%] before:content-[url('/images/Circle_visualize.svg')] md:mt-0 md:text-[55px] lg:mx-0 lg:mb-32 lg:mt-0 lg:text-left lg:text-[55px] lg:before:bottom-0 lg:before:left-[12%]"
          >
            A visualization powertool
          </Typography>

          <Typography
            as="p"
            className="mx-auto text-center text-grayscale-gray-50 sm:max-w-[90%] md:text-18 lg:mx-0 lg:text-left min-[1400px]:max-w-full xl:max-w-full"
            variant="body-md-book"
          >
            {experimentVariantId === ExperimentVariant.A
              ? 'Some ideas can only be expressed visually. Illustrate, map ideas, and communicate more effectively with reMarkable.'
              : paragraph}
          </Typography>

          <Video
            className="mx-auto mt-40 lg:mx-0"
            url="https://youtu.be/T_ix3_g58vU"
            color={'white'}
            duration={isMobileOrTablet ? '52 sec' : ''}
          />
        </div>

        <div
          data-cy="carousel"
          className={
            'my-0 mr-0 ml-[2vw] w-[130vw] md:my-32 md:mr-0 lg:ml-0 lg:w-[140%] lg:overflow-hidden xl:col-span-2 xl:col-start-3 xl:ml-[2vw] [@media(min-width:1920px)]:col-start-2 [@media(min-width:1920px)]:col-end-2'
          }
        >
          <ImageCarousel imageArray={carouselImages} width="60%" />
        </div>

        {experimentVariantId === ExperimentVariant.A ? null : (
          <>
            <div className={'flex flex-col justify-center md:hidden'}>
              <button
                className={
                  'mx-auto mt-[60px] mb-[10px] flex h-[38px] items-center rounded-[25px] border-none bg-grayscale-gray-50 pl-16'
                }
                aria-controls={'expandable'}
                aria-expanded={isOpen}
                onClick={() => {
                  setIsOpen(!isOpen);
                }}
              >
                {isOpen ? (
                  <>
                    <X size={24} color="#222222" />
                    <Typography
                      as="span"
                      className="my-0 mr-[13px] ml-12 text-grayscale-gray-800"
                      variant="interface-md-book"
                    >
                      Close
                    </Typography>
                  </>
                ) : (
                  <>
                    <Plus size={26} color="#222222" />
                    <Typography
                      as="span"
                      className="my-0 mr-[13px] ml-12 text-grayscale-gray-800"
                      variant="interface-md-book"
                    >
                      Our tools
                    </Typography>
                  </>
                )}
              </button>

              <AnimateHeight height={isOpen ? 'auto' : 0} duration={300} role="dialog">
                <MobileExpandableMenu data={data} />
              </AnimateHeight>
            </div>
            <Icons data={data} />
          </>
        )}
      </div>
    </section>
  );
}

export default Visualization;
