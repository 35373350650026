import { Typography } from '@remarkable/ark-web';
import { Plus } from 'phosphor-react';
import React, { useState } from 'react';
import AnimateHeight from 'react-animate-height';
import Icon from 'src/components/Icon';
import SanityBlockComponent from 'src/helper-components/SanityBlockComponent';

interface MobileMenuData {
  icon: { component: React.FunctionComponent<React.SVGProps<SVGSVGElement>>; ariaLabelledby: string };
  text: string;
  subText: any;
}

const MobileMenu = ({ data }: { data: MobileMenuData[] }) => {
  const [openTab, setOpenTab] = useState(-1);

  return (
    <div className="my-16 list-none bg-grayscale-gray-700 z-1 md:hidden">
      {data.map((item, index) => {
        return (
          <React.Fragment key={index}>
            <div
              className="p-[30px] cursor-pointer whitespace-nowrap"
              onClick={() => {
                setOpenTab(index != openTab || openTab < 0 ? index : -1);
              }}
            >
              <div className="flex justify-center">
                <Icon component={item.icon.component} ariaLabelledby={item.icon.ariaLabelledby} color="#EFEFEF" />
                <Typography as="span" className="ml-16 text-center" variant="body-lg-book">
                  {item.text}
                </Typography>
                <Plus size={26} color="#efefef" className="ml-auto" />
              </div>
            </div>
            {index != openTab && index != data.length - 1 && (
              <div className="flex justify-center">
                <div className="border-solid border border-grayscale-gray-600 w-[90%]" />
              </div>
            )}

            <AnimateHeight height={index == openTab ? 'auto' : 0} duration={300} role="dialog">
              <div className="flex justify-center p-24 bg-grayscale-black">
                <Typography as="div" variant="body-md-book">
                  <SanityBlockComponent input={item.subText} />
                </Typography>
              </div>
            </AnimateHeight>
          </React.Fragment>
        );
      })}
    </div>
  );
};

export default MobileMenu;
