import BlackInk from 'src/assets/Icons/icon_specific_blackink_32.svg';
import PaperDisplay from 'src/assets/Icons/icon_specific_paperdisplay_32.svg';
import Pen from 'src/assets/Icons/icon_specific_pen_32.svg';
import Responsive from 'src/assets/Icons/icon_specific_responsive_32.svg';
import { sanityImgObjectFromGenericImage } from 'src/components/Image/SanityImage/sanityImageHelpers';
import SanityResponsiveImage from 'src/components/Image/SanityResponsiveImage';
import SanityBlockComponent from 'src/helper-components/SanityBlockComponent';
import { ArrayElement } from 'src/typings/arrayElement';
import { SanityImage } from 'src/typings/sanityTypes';
import { WebsiteSectionContentWithTexts } from 'src/typings/websiteContent';

import { Typography } from '@remarkable/ark-web';
import { Tab, Tabs } from './Tabs';

interface Props {
  images: SanityImage[];
  icons: string[];
  paragraphs: ArrayElement<WebsiteSectionContentWithTexts['Texts']>['paragraphs'][];
}

const tabData = [
  {
    label: 'A paper-like surface',
    icon: { component: PaperDisplay, ariaLabelledby: 'specific_paperdisplay' },
  },
  {
    label: 'Incredible responsiveness',
    icon: { component: Responsive, ariaLabelledby: 'responsive' },
  },
  {
    label: 'Minimal pen-to-ink distance',
    icon: { component: Pen, ariaLabelledby: 'pen' },
  },
  {
    label: 'Crisp black ink',
    icon: { component: BlackInk, ariaLabelledby: 'black inc' },
  },
];

const TabsPaperFeel = (props: Props) => {
  const { paragraphs, images } = props;
  return (
    <div className="hidden mx-24 my-0 md:block max-w-[1360px] xl:m-auto">
      <Tabs>
        {tabData.map((item, index) => {
          return (
            <Tab key={index} label={item.label} color="#FEFEFE" icon={item.icon}>
              <div className="grid grid-cols-2">
                {paragraphs[index] && (
                  <div className="mt-32 ml-32 max-w-[300px] w-[80%] lg:mt-56 lg:ml-56 xl:max-w-[365px] xl:mt-[80px] xl:ml-[112px]">
                    <Typography as="div" variant="body-md-book">
                      <SanityBlockComponent input={paragraphs[index]} />
                    </Typography>
                  </div>
                )}
                <SanityResponsiveImage
                  imgDefault={{
                    sanityImgObject: { ...sanityImgObjectFromGenericImage(images[index]) },
                    fitMode: 'cover',
                  }}
                  alt={images[index].alt}
                  pictureClassName="h-[250px] lg:h-[300px] xl:h-[400px]"
                />
              </div>
            </Tab>
          );
        })}
      </Tabs>
    </div>
  );
};

export default TabsPaperFeel;
