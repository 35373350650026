import { getSanityImageResponsive, Image, Typography } from '@remarkable/ark-web';
import React from 'react';
import { extractGenericImageBySelector, getParagraphTextFromWebsiteContent } from 'src/helpers/sanityHelpers';
import { WebsiteSectionContentWithImages, WebsiteSectionContentWithTexts } from 'src/typings/websiteContent';
import sanityClient from 'src/utils/sanityClient';

interface Props {
  sectionContent: WebsiteSectionContentWithTexts & WebsiteSectionContentWithImages;
}

export const ReadWebArticles: React.FC<Props> = ({ sectionContent }: Props) => {
  const header = getParagraphTextFromWebsiteContent(sectionContent, 'section2-header');
  const paragraph = getParagraphTextFromWebsiteContent(sectionContent, 'section2-paragraph');
  const image = extractGenericImageBySelector(sectionContent, 'image3');

  return (
    <div className="flex flex-col lg:grid lg:grid-cols-[1fr_1fr] lg:gap-x-32 justify-center items-center mt-32 md:mt-48 [&>picture]:w-full">
      <Image
        className="w-full h-full sm:h-[420px] md:h-max lg:h-[460px] object-cover"
        {...getSanityImageResponsive({
          alt: image.alt ?? '',
          sanityClient: sanityClient,
          sanityImage: image,
          options: { width: 426 },
          sources: [
            {
              media: '(min-width: 1024px)',
              options: { height: 460 },
            },
            {
              media: '(min-width: 768px)',
              options: { width: 900 },
            },
            {
              media: '(min-width: 475px)',
              options: { width: 623 },
            },
          ],
        })}
      />

      <div className="w-full text-left lg:px-24 xl:px-0 md:mx-auto xl:ml-[96px] mb-32 lg:mb-0">
        <Typography
          as="h3"
          variant="responsive-heading-lg"
          className="m-0 p-0 font-serif pt-24 md:pt-32"
          style={{ maxWidth: '526px' }}
        >
          {header}
        </Typography>

        <Typography variant="responsive-body-md" className="mt-8 lg:mt-24 mb-32 max-w-[520px]">
          {paragraph}
        </Typography>
      </div>
    </div>
  );
};
