import { getSanityImage, Image, Typography } from '@remarkable/ark-web';
import { extractGenericImageBySelector, getParagraphTextFromWebsiteContent } from 'src/helpers/sanityHelpers';
import { WebsiteSectionContentWithImages, WebsiteSectionContentWithTexts } from 'src/typings/websiteContent';
import { client } from 'src/utils/sanityClient';

interface Props {
  sectionContent: WebsiteSectionContentWithTexts & WebsiteSectionContentWithImages;
}

const Folios = ({ sectionContent }: Props) => {
  if (sectionContent == null) {
    return null;
  }

  const getParagraphText = (selectorId) => getParagraphTextFromWebsiteContent(sectionContent, selectorId);
  const paragraph = getParagraphText('paragraph');
  const foliosList = ['typeFolio', 'bookFolio', 'folio'];

  return (
    <section className="px-24 py-64 bg-brand-brown-400 md:py-[120px] md:px-56" id="Accessories">
      <Typography as="h3" variant="heading-sm-italic" className="mt-0 mb-16 text-center text-white md:mb-24">
        {sectionContent.subHeader}
      </Typography>

      <Typography as="h2" variant="responsive-heading-xl" className="mt-0 mb-24 text-center text-white">
        {sectionContent.header}
      </Typography>

      <Typography
        variant="responsive-body-lg"
        className="text-white text-center mt-0 mb-[18px] md:mb-32 max-w-[700px] mx-auto"
      >
        {paragraph}
      </Typography>

      <div className="grid grid-cols-1 grid-rows-3 md:grid-cols-3 md:grid-rows-1 md:gap-x-40 text-center max-w-[1360px] mx-auto md:mb-32">
        {foliosList.map((folio) => {
          const folioImage = extractGenericImageBySelector(sectionContent, `${folio}_folioImage`);

          return (
            <div className="mb-12 justify-self-center" key={folio}>
              <Image
                className="max-w-[350px] w-full"
                {...getSanityImage({
                  alt: folioImage.alt ?? '',
                  sanityClient: client,
                  sanityImage: folioImage,
                  options: { width: 350 },
                })}
              />
              <Typography as="h3" variant="responsive-heading-sm" className="mt-0 mb-8 text-white">
                {getParagraphText(`${folio}_folioTitle`)}
              </Typography>

              <Typography
                variant="responsive-body-md"
                className="text-white max-w-[320px] lg:max-w-[300px] mx-auto mt-0"
              >
                {getParagraphText(`${folio}_folioParagraph`)}
              </Typography>
            </div>
          );
        })}
      </div>
    </section>
  );
};

export default Folios;
