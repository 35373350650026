import { Button, getSanityImageResponsive, Image } from '@remarkable/ark-web';
import { setTrackClick } from '@remarkable/tracking-browser/lib/ampli';
import { Navigate, NavigateProperties } from 'ampli-types';
import NextLink from 'next/link';
import { ArrowUpRight } from 'phosphor-react';
import React from 'react';
import { extractGenericImageBySelector, getParagraphTextFromWebsiteContent } from 'src/helpers/sanityHelpers';
import { useExperimentContext } from 'src/services/experiments/ExperimentContext';
import { ExperimentVariant } from 'src/services/experiments/optimize';
import { ComponentLocations } from 'src/services/tracking/eventTypes';
import { WebsiteSectionContentWithImages, WebsiteSectionContentWithTexts } from 'src/typings/websiteContent';
import sanityClient from 'src/utils/sanityClient';
interface Props {
  sectionContent: WebsiteSectionContentWithTexts & WebsiteSectionContentWithImages;
}

type ITrackClickData = Required<Pick<NavigateProperties, 'text' | 'link_source'>> &
  Partial<Pick<NavigateProperties, 'action' | 'type' | 'pathname'>> & { moduleId?: string };

const trackClickDataLearnAboutConnectModule = (props: ITrackClickData): NavigateProperties => {
  const { action = 'open', type = 'link', pathname, moduleId, text, link_source } = props;

  return {
    action,
    type,
    text,
    link_source,
    referrer: moduleId ? `#${moduleId}` : pathname,
    component_location: ComponentLocations.FRONT_PAGE.MODULE,
  };
};

const Connect: React.FC<Props> = ({ sectionContent }) => {
  const { experimentVariantId } = useExperimentContext();
  if (!sectionContent) {
    return null;
  }

  const sanityImage = extractGenericImageBySelector(sectionContent, 'connectDevicesImage');
  const { header } = sectionContent;
  const moduleDescription = getParagraphTextFromWebsiteContent(sectionContent, 'connectModuleDescription');
  const aboutConnect = getParagraphTextFromWebsiteContent(sectionContent, 'aboutConnectButton');
  const disclamer = getParagraphTextFromWebsiteContent(sectionContent, 'disclamer');

  const moduleId = 'connect-10';
  const learnMoreBtnHref = '/store/connect';
  return (
    <section id={moduleId} className="bg-brand-sand-50 py-48 lg:py-[80px] xl:pt-[132px] xl:pb-[174px] px-24 md:px-56">
      <div className="grid gap-x-32 md:grid-cols-[1fr_1fr] md:grid-rows-[auto_auto_1fr] md:mx-auto md:w-full md:max-w-[1024px] lg:max-w-[1200px]">
        <div
          role="img"
          className="
          flex grow relative 
          justify-self-center items-center justify-center sm:justify-center md:align-middle
          md:h-full
          w-full xs:h-[400px] 
          md:min-w-[375px]
          xs:max-w-[600px] sm:max-w-[800px] md:max-w-[400px] lg:max-w-[800px] 
          md:col-start-2 md:col-span-full md:row-span-full 
          xs:w-[185%] sm:w-[140%]
          xs:pl-[96px] md:pl-0
          lg:pr-20 xl:p-0 xl:m-0
          "
        >
          <Image
            className="w-full object-contain rounded xs:max-h-[400px] md:h-full xl:scale-110"
            loading="lazy"
            {...getSanityImageResponsive({
              sanityClient,
              sanityImage,
              alt: `${sanityImage.alt ?? 'Illustration of connected and synchronized devices'}`,
              options: {
                width: 500,
              },
            })}
          />
        </div>
        <div className="text-center md:text-left xs:min-w-[270px] lg:max-w-[450px] md:mt-24 justify-self-center md:justify-self-start order-first">
          <h1 className="mt-0 heading-md md:text-42 lg:text-54 xs:text-42 xs:mb-24 md:mb-16">
            {experimentVariantId === ExperimentVariant.A ? 'Take notes anywhere' : header}
          </h1>
        </div>
        <div className="max-w-[475px] sm:mt-16 md:mt-0 text-center md:text-left xs:justify-self-center md:justify-self-start ms:justify-self-start">
          <p className="body-lg-book text-16 lg:text-18 xs:max-w-[400px] sm:max-w-[550px] lg:max-w-[400px]">
            {experimentVariantId === ExperimentVariant.A
              ? "Write, edit, and refine notes across your smart devices with our mobile and desktop apps. Add ideas on the go, or pick up where you left off. All your work's accessible and ready to build on, wherever you are."
              : moduleDescription}
          </p>
        </div>
        <div className="flex flex-col items-center px-20 mt-24 text-center sm:px-0 lg:mt-32 md:text-left md:items-start md:justify-self-start">
          <NextLink href={learnMoreBtnHref} target="_blank" passHref legacyBehavior>
            <Button
              as="a"
              target="_blank"
              variant="secondary"
              {...setTrackClick(
                new Navigate(
                  trackClickDataLearnAboutConnectModule({
                    text: aboutConnect,
                    link_source: learnMoreBtnHref,
                    moduleId: moduleId,
                  })
                )
              )}
            >
              <span className="font-semibold">{aboutConnect}</span>
              <ArrowUpRight size={13} />
            </Button>
          </NextLink>
          <p className=" mb-0 mt-16 text-14 md:text-left text-dark opacity-60 font-normal max-w-[300px] md:max-w-[300px]">
            {disclamer}
          </p>
        </div>
      </div>
    </section>
  );
};

export default Connect;
