import { CaretCircleLeft, CaretCircleRight } from 'phosphor-react';
import { useState } from 'react';
import SwipeableViews from 'react-swipeable-views';
import { bindKeyboard, virtualize } from 'react-swipeable-views-utils';
import { sanityImgObjectFromGenericImage } from 'src/components/Image/SanityImage/sanityImageHelpers';
import SanityResponsiveImage from 'src/components/Image/SanityResponsiveImage';
import { GenericImage } from 'src/typings/runtime-typecheck/sanityGenericImage';

const VirtualizeSwipeableViews = bindKeyboard(virtualize(SwipeableViews));

const moduleFunction = (n: number, m: number) => {
  return ((n % m) + m) % m;
};

interface Props {
  imageArray: GenericImage[];
  width?: string;
}

function ImageCarousel({ imageArray, width }: Props) {
  const [imageIndex, setImageIndex] = useState(imageArray.length);
  if (imageArray.length < 1) {
    return null;
  }

  let imgSizeSet;
  if (width?.includes('px')) {
    imgSizeSet = [{ bp: 0, sanityImgOptions: { width: width.replace('px', '') } }];
  } else if (width?.includes('%')) {
    const widthFactor = parseInt(width.replace('%', '')) / 100;
    imgSizeSet = [
      { bp: 375 /* xs */, sanityImgOptions: { width: Math.ceil(768 * widthFactor) } },
      { bp: 768 /* md */, sanityImgOptions: { width: Math.ceil(1024 * widthFactor) } },
      { bp: 1024 /* lg */, sanityImgOptions: { width: Math.ceil(1440 * widthFactor) } },
      { bp: 1440 /* xl */, sanityImgOptions: { width: Math.ceil(1680 * widthFactor) } },
      { bp: 1680 /* xxl */, sanityImgOptions: { width: Math.ceil(1920 * widthFactor) } },
      { bp: 1920 /* xxxl */, sanityImgOptions: { width: Math.ceil(2560 * widthFactor) } },
    ];
  }

  const slideRenderer = ({ key, index }: { key: number; index: number }) => {
    const value = moduleFunction(index, imageArray.length);
    return (
      <SanityResponsiveImage
        key={key}
        imgDefault={{
          sanityImgObject: { ...sanityImgObjectFromGenericImage(imageArray[value]) },
          fitMode: 'cover',
        }}
        alt={imageArray[value]?.alt ?? ''}
        pictureClassName=""
        imgSizeSet={imgSizeSet}
      />
    );
  };

  return (
    <>
      <VirtualizeSwipeableViews
        style={{ position: 'relative', overflow: 'visible', alignSelf: 'normal', width: width || '100%' }}
        containerStyle={{ padding: '0 32px 0 0 ' }}
        slideStyle={{ margin: '16px', overflow: 'hidden' }}
        onChangeIndex={(newIndex) => setImageIndex(newIndex)}
        index={imageIndex}
        overscanSlideAfter={5}
        overscanSlideBefore={5}
        enableMouseEvents
        slideRenderer={slideRenderer}
      />
      <div className={'flex flex-row justify-center w-[calc(100vw-16px)] lg:w-full'}>
        <button
          data-cy="carousel-left-button"
          aria-label="carousel left button"
          className="px-6"
          onClick={() => {
            setImageIndex(imageIndex - 1);
          }}
        >
          <CaretCircleLeft size={28} color="#efefef" weight="light" />
        </button>
        <button
          data-cy="carousel-right-button"
          aria-label="carousel right button"
          className="px-6"
          onClick={() => {
            setImageIndex(imageIndex + 1);
          }}
        >
          <CaretCircleRight size={28} color="#efefef" weight="light" />
        </button>
      </div>
    </>
  );
}

export default ImageCarousel;
