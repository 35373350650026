import { getSectionContentBySelectorId } from 'src/helpers/sanityHelpers';
import { WebsiteContentWithTextsAndImages } from 'src/queries/groq/sanityWebsiteContent';
import { WebsiteSectionContentWithImages, WebsiteSectionContentWithTexts } from 'src/typings/websiteContent';

import SectionMarkers from './SectionMarkers';

interface Props {
  sectionContent: WebsiteContentWithTextsAndImages;
}

export default ({ sectionContent }: Props) => {
  return (
    <>
      <SectionMarkers
        sectionContent={
          getSectionContentBySelectorId(sectionContent, 'markersSection') as WebsiteSectionContentWithTexts &
            WebsiteSectionContentWithImages
        }
      />
    </>
  );
};
