import { getSanityImageResponsive, Image, Typography } from '@remarkable/ark-web';
import cx from 'classnames';
import { useEffect, useState } from 'react';
import { RM110ReferralDiscount } from 'src/helpers/rm110ReferralDiscount';
import { extractGenericImageBySelector, getParagraphTextFromWebsiteContent } from 'src/helpers/sanityHelpers';
import { WebsiteSectionContentWithImages, WebsiteSectionContentWithTexts } from 'src/typings/websiteContent';
import sanityClient from 'src/utils/sanityClient';

import { useExperimentContext } from 'src/services/experiments/ExperimentContext';
import { ExperimentVariant } from 'src/services/experiments/optimize';
import { SanityProductImageSchemaType } from 'src/typings/runtime-typecheck/sanityImage';
import { DataTheme } from '../..';
import HeroCtaButton from '../HeroOriginal/components/HeroCtaButton';
import ScrollButton from '../HeroOriginal/components/ScrollButton';

type isDesktop = undefined | boolean;

interface HeroTypeFolioAProps {
  sectionContent: WebsiteSectionContentWithTexts & WebsiteSectionContentWithImages;
  referral: RM110ReferralDiscount;
  dataTheme: DataTheme;
  activeNotification: boolean;
}

const getVideoSrc = (sectionContent) =>
  getParagraphTextFromWebsiteContent(sectionContent, 'videoMobileSource-heroSeabird');

const getSanityImage = (sectionContent) =>
  extractGenericImageBySelector(sectionContent, 'videoMobileImage-heroSeabird');

const HeroImage = ({ image }: { image: SanityProductImageSchemaType }) => {
  return (
    <Image
      className="object-contain min-[650px]:h-full min-[650px]:ml-auto min-[650px]:mr-[0px] md:mr-[30px] lg:mr-[60px] min-[650px]:w-[50%] block w-full mx-auto mb-24 min-[650px]:mb-0 aspect-square min-[650px]:aspect-square max-w-[650px]"
      {...getSanityImageResponsive({
        sanityClient,
        sanityImage: image,
        alt: image.alt ?? '',
        options: { width: 650 },
        sources: [
          {
            media: '(min-width: 320px)',
            options: { width: 375 },
          },
          {
            media: '(min-width: 376px)',
            options: { width: 650 },
          },
        ],
      })}
    />
  );
};

export const HeroTypeFolio = ({
  sectionContent,
  referral,
  dataTheme = 'on-light',
  activeNotification,
}: HeroTypeFolioAProps): JSX.Element => {
  const [isDesktop, setIsDesktop] = useState<isDesktop>(undefined);
  const [viewFallbackImage, setFallbackImage] = useState<boolean>(false);
  const [isBadge, setBadge] = useState<boolean>(false);

  const { header, subHeader } = sectionContent;
  const paragraphHeader = getParagraphTextFromWebsiteContent(sectionContent, 'paragraphHeader-heroSeabird');
  const imageObject = getSanityImage(sectionContent);
  const videoSrc = getVideoSrc(sectionContent);

  const { experimentVariantId } = useExperimentContext();

  useEffect(() => {
    if (!window || !window.matchMedia) {
      console.warn('MainPageHero: no matchMedia support');
      return;
    }
    const mediaQueryReduce = window.matchMedia('(prefers-reduced-motion: reduce)');

    const handleMediaQueryReduceMotionChange: (event: { matches: boolean }) => void = ({ matches }) => {
      setFallbackImage(matches);
    };

    handleMediaQueryReduceMotionChange(mediaQueryReduce);
  }, []);

  useEffect(() => {
    if (!window || !window.matchMedia) {
      console.warn('MainPageHero: no matchMedia support');
      return;
    }
    const mediaQuery = window.matchMedia('(min-width: 650px)');

    const handleMediaQueryChange: (event: { matches: boolean }) => void = ({ matches }) => {
      setIsDesktop(matches);
    };

    // Run the media query change handler once.
    handleMediaQueryChange(mediaQuery);

    // Add the callback function as a listener to the query
    mediaQuery.addEventListener('change', handleMediaQueryChange);

    return () => {
      mediaQuery.removeEventListener('change', handleMediaQueryChange);
    };
  }, []);

  return (
    <div
      data-theme={dataTheme}
      className={cx(
        'bg-[#F2EEEB] min-[650px]:bg-[#F4F0ED] relative aspect-square min-[650px]:aspect-video md:aspect-video max-h-[841.750px] w-full min-[650px]:pt-0 min-[650px]:mx-auto',
        { 'pt-[62px]': !activeNotification }
      )}
    >
      <div className="relative aspect-square min-[650px]:aspect-video max-w-[1400px] mx-auto min-[650px]:pt-[40px] mb-24 min-[650px]:mb-0">
        <div className="block right-0 absolute top-0 min-[650px]:h-full min-[650px]:ml-auto min-[650px]:mr-[0px] md:mr-[30px] lg:mr-[60px] min-[650px]:w-[50%] w-full mx-auto mb-24 min-[650px]:mb-0 aspect-square min-[650px]:aspect-square max-w-[650px]">
          <img
            src="/svgs/base_worlds_thinnest_badge.svg"
            alt=""
            className={cx(
              'absolute w-[110px] min-[650px]:w-[120px] lg:w-[140px] right-8 xs:right-12 min-[420px]:right-24 sm:right-48 min-[560px]:right-[80px] min-[650px]:right-8 md:right-20 min-[900px]:right-32 lg:right-40 min-[1160px]:right-48 xl:right-64 top-[50%] translate-y-[-90%] xs:translate-y-[-110%] sm:translate-y-[-130%] min-[560px]:translate-y-[-150%] min-[650px]:translate-y-[-70%] md:translate-y-[-90%] min-[900px]:translate-y-[-100%] lg:translate-y-[-95%] min-[1160px]:translate-y-[-110%] xl:translate-y-[-120%] transition duration-300 ease-in-out',
              {
                'opacity-100': isBadge || isDesktop === false,
                'opacity-0': !isBadge,
              }
            )}
          />
        </div>

        {viewFallbackImage ? (
          <HeroImage image={imageObject} />
        ) : (
          <>
            {isDesktop && (
              <video
                src={videoSrc}
                className="hidden min-[650px]:block min-[650px]:h-full min-[650px]:ml-auto min-[650px]:mr-[0px] md:mr-[30px] lg:mr-[60px] min-[650px]:w-[50%] w-full mx-auto mb-24 min-[650px]:mb-0 aspect-square min-[650px]:aspect-square max-w-[650px]"
                tabIndex={-1}
                muted
                playsInline
                onEnded={() => {
                  setBadge(true);
                }}
                onError={() => {
                  setFallbackImage(true);
                }}
                autoPlay
              />
            )}
            {!isDesktop && isDesktop === false && <HeroImage image={imageObject} />}
          </>
        )}
      </div>

      <div className="opacity-100 bg-[#F2EEEB] text-center pb-56 px-24 max-w-[1400px] min-[650px]:pt-0 min-[650px]:absolute min-[650px]:top-0 min-[650px]:text-left min-[650px]:mt-0 min-[650px]:bg-transparent min-[650px]:aspect-video min-[650px]:w-full min-[650px]:pb-0 min-[650px]:grid min-[650px]:content-center min-[650px]:pl-[12%] md:pl-[12%] lg:pt-40 lg:left-[50%] lg:translate-x-[-50%] lg:mt-0 lg:pl-[13%] xl:pt-40 xl:pl-[160px]">
        <Typography
          as="h1"
          variant="responsive-heading-xl"
          className="mb-4 mt-0 min-[650px]:mb-4 min-[650px]:text-[36px] md:text-42 lg:text-54 xl:text-68 lg:mb-12"
        >
          {experimentVariantId === ExperimentVariant.A ? 'reMarkable' : header}
        </Typography>
        <Typography
          as="h2"
          variant="heading-sm-italic"
          className="mb-16 mt-0 min-[650px]:mb-12 min-[650px]:text-[28px] lg:text-42 xl:text-54 lg:mb-24"
        >
          {subHeader}
        </Typography>
        <Typography
          variant="body-md-book"
          className="text-center max-w-[300px] mx-auto px-20 min-[650px]:mx-0 min-[650px]:text-left min-[650px]:px-0 md:px-0 min-[650px]:max-w-[240px] md:max-w-[230px] lg:max-w-[320px] xl:max-w-[380px] min-[650px]:text-16 md:text-16 lg:text-20 xl:text-24"
        >
          {experimentVariantId === ExperimentVariant.A
            ? 'Replace your notebooks and printouts with the only tablet that feels like paper.'
            : paragraphHeader}
        </Typography>
        <HeroCtaButton
          referral={referral}
          className="mx-auto mt-32 min-[650px]:mt-20 min-[650px]:mx-0 md:mx-0 lg:mt-32"
        />

        <ScrollButton
          dataTheme={dataTheme}
          animate={false}
          className="min-[650px]:hidden md:flex flex flex-col items-center mt-40 min-[650px]:items-start min-[650px]:mt-24 md:flex-row md:mt-40 lg:mt-[72px] xl:mt-[80px]"
        />
      </div>
    </div>
  );
};
