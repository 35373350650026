import { createContext, FC, useContext } from 'react';

import Cookies from 'js-cookie';
import { ExperimentProps, ExperimentVariant } from './optimize';

type ExperimentContext = ExperimentProps;

const ExperimentContext = createContext<ExperimentProps>({
  experimentId: '',
  experimentVariantId: ExperimentVariant.CG,
});

export const ExperimentProvider: FC<ExperimentContext> = ({ children, ...context }) => (
  <ExperimentContext.Provider value={context}>{children}</ExperimentContext.Provider>
);

export const useExperimentContext = (): ExperimentProps => useContext(ExperimentContext);

export const getExperimentVariant = (experimentId: string): string | undefined => {
  const experimentVariant = Cookies.get(experimentId);
  return experimentVariant?.split(/\./)[1];
};
