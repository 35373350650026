import { getSanityImage, Image, Typography } from '@remarkable/ark-web';
import cx from 'classnames';
import React from 'react';
import { connect } from 'react-redux';
import Thinbag from 'src/assets/Icons/icon_specific_thinbag_32.svg';
import Thinwrite from 'src/assets/Icons/icon_specific_thinwrite_32.svg';
import { extractGenericImageBySelector, extractLocalizedParagraphsBySelector } from 'src/helpers/sanityHelpers';
import { State } from 'src/redux/reducers';
import { SanityLocalizedTextsParagraph } from 'src/typings/sanityTypes';
import { WebsiteSectionContentWithImages, WebsiteSectionContentWithTexts } from 'src/typings/websiteContent';
import sanityClient from 'src/utils/sanityClient';
import PerkItem from './components/PerkItem';

interface Props {
  country: string;
  sectionContent: WebsiteSectionContentWithTexts & WebsiteSectionContentWithImages;
}

const PaperThinContainer: React.FunctionComponent<Props> = ({ sectionContent, country }) => {
  if (!sectionContent) {
    return null;
  }

  const tabletImage = extractGenericImageBySelector(sectionContent, 'mobileImage');
  const afterImage2 = extractGenericImageBySelector(sectionContent, 'image2');
  const texts = sectionContent.Texts;

  return (
    <div className="">
      <section className="bg-brand-blue-900 text-grayscale-gray-50 pt-0 px-24 pb-20 md:pt-[64px] md:pb-56 lg:pt-[136px] lg:pb-[72px]">
        <div className="max-w-[1280px] text-center pt-32 md:pt-0 mt-0 mx-auto mb-0 md:mb-48">
          <Typography
            as="h2"
            variant="heading-2xl"
            className="text-[70px] lg:text-[110px] text-grayscale-gray-50 my-0 mx-auto flex flex-col justify-center z-[5] relative py-[1em] md:py-0 px-0 max-w-[300px] md:max-w-none"
          >
            <span>
              The world's <br /> thinnest tablet
            </span>
          </Typography>

          {tabletImage && (
            <div
              className={cx([
                'relative z-0 mt-32',
                'w-[850px] md:w-full',
                'left-1/2 md:left-0',
                'transform md:transform-none  translate-y-[-475px] rotate-90 scale-50 origin-left',
              ])}
            >
              <Image
                className="object-cover max-w-[95%]"
                loading="lazy"
                {...getSanityImage({
                  sanityClient: sanityClient,
                  sanityImage: tabletImage,
                  alt: tabletImage.alt ?? '',
                  options: { width: 1296 },
                })}
              />
            </div>
          )}
        </div>

        <Typography
          as="p"
          variant="body-xl-book"
          className="text-24 text-light mt-0 mx-auto mb-48 text-center max-w-[510px] md:max-w-[720px]"
        >
          {extractLocalizedParagraphsBySelector(texts as SanityLocalizedTextsParagraph[], 'paragraph', country)}
        </Typography>

        <div className={'flex flex-col md:flex-row justify-center items-center'}>
          <PerkItem
            icon={{ component: Thinwrite, ariaLabelledby: 'specific_thinwrite' }}
            sectionContent={sectionContent}
            index={1}
          />
          <PerkItem
            icon={{ component: Thinbag, ariaLabelledby: 'specific_thinbag' }}
            sectionContent={sectionContent}
            index={2}
          />
        </div>
      </section>

      <div className="hidden max-h-[587px] w-full overflow-hidden md:block">
        <Image
          loading="lazy"
          className="max-h-[587px] object-cover w-[100vw]"
          {...getSanityImage({
            sanityClient: sanityClient,
            sanityImage: afterImage2,
            alt: afterImage2.alt ?? '',
            options: { width: 2000 },
          })}
        />
      </div>
    </div>
  );
};

const mapStateToProps = (state: State) => ({
  country: state.countryDetector.country,
});

export default connect(mapStateToProps)(PaperThinContainer);
