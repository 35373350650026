import { Typography } from '@remarkable/ark-web';
import { PressPublicationsData } from 'src/queries/groq/publicationsData';
import Carousel from './Carousel';

type Props = {
  pressPublications: PressPublicationsData;
  country: string;
};

function getPressReviewsByCountry(pressPublications: PressPublicationsData, country: string) {
  const hasCountrySpecificReviews = !!pressPublications.find((item) => item.country != null && item.country == country);
  if (hasCountrySpecificReviews) {
    return pressPublications.filter((item) => item.country != null && item.country == country);
  }
  return pressPublications.filter((item) => item.country == null);
}

export const PressReviews: React.FC<Props> = ({ pressPublications, country }) => {
  const pressReviews = getPressReviewsByCountry(pressPublications, country);

  return (
    <section id="press">
      <div className="flex flex-col items-center justify-center bg-brand-sand-100 text-dark my-0 mx-auto w-full py-32 md:py-64 md:px-24 text-center">
        <Typography as="h2" variant="interface-sm-caps" className="font-medium xl:text-16 px-56 md:px-0 pb-16 md:pb-32">
          PRESS REVIEWS
        </Typography>
        <Carousel pressReviews={pressReviews} />
      </div>
    </section>
  );
};
