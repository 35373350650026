import { sanityImgObjectFromGenericImage } from 'src/components/Image/SanityImage/sanityImageHelpers';
import SanityResponsiveImage from 'src/components/Image/SanityResponsiveImage';
import { getParagraphTextFromWebsiteContent } from 'src/helpers/sanityHelpers';
import { WebsiteSectionContentWithImages, WebsiteSectionContentWithTexts } from 'src/typings/websiteContent';

import Magnet from 'src/assets/Icons/icon_specific_magnet_32.svg';
import NoCharge from 'src/assets/Icons/icon_specific_nocharge_32.svg';
import Pen from 'src/assets/Icons/icon_specific_pen_32.svg';
import MarkerPerk from './components/MarkerPerk';

interface Props {
  sectionContent: WebsiteSectionContentWithTexts & WebsiteSectionContentWithImages;
}

const SectionMarkers = ({ sectionContent }: Props) => {
  if (sectionContent == null) {
    return null;
  }

  return (
    <section className="py-56 px-0 bg-[#949da2] text-dark overflow-hidden lg:py-[120px]">
      <div className="px-24 py-0">
        <h2 className="mt-0 mb-8 italic text-center text-32">{sectionContent.header}</h2>
        <h2 className="mt-0 mb-48 text-center text-42 md:text-68">
          Responsive. <span>Precise.</span> <span className="inline-block">Hassle-free.</span>
        </h2>
        <p className="text-16 max-w-[676px] mt-0 mx-auto mb-48 font-book leading-150 md:text-center md:text-24">
          {getParagraphTextFromWebsiteContent(sectionContent, 'paragraph1')}
        </p>
      </div>
      <div className="w-[80vw] max-w-[1200px] mx-auto mb-20">
        <figure className="w-full flex justify-center text-right flex-col lg:ml-[-100px] m-auto mb-20">
          <figcaption className="mr-[5%]">Marker</figcaption>
          <SanityResponsiveImage
            imgDefault={{
              sanityImgObject: { ...sanityImgObjectFromGenericImage(sectionContent.images[0]) },
              fitMode: 'contain',
            }}
            alt={sectionContent.images[0].alt ?? ''}
          />
        </figure>
        <figure className="w-full flex justify-center text-right flex-col lg:ml-[100px] m-auto">
          <figcaption className="mr-[5%]">Marker Plus with built-in eraser</figcaption>
          <SanityResponsiveImage
            imgDefault={{
              sanityImgObject: { ...sanityImgObjectFromGenericImage(sectionContent.images[1]) },
              fitMode: 'contain',
            }}
            alt={sectionContent.images[1].alt ?? ''}
          />
        </figure>
      </div>
      <div className="flex md:justify-between px-24 flex-col md:flex-row md:items-center m-auto md:space-x-16 max-w-[300px] md:max-w-[1000px]">
        <MarkerPerk
          icon={{ component: NoCharge, ariaLabelledby: 'No charge' }}
          text={getParagraphTextFromWebsiteContent(sectionContent, 'perk1')}
        />
        <MarkerPerk
          icon={{ component: Magnet, ariaLabelledby: 'Magnet' }}
          text={getParagraphTextFromWebsiteContent(sectionContent, 'perk2')}
        />
        <MarkerPerk
          icon={{ component: Pen, ariaLabelledby: 'Pen' }}
          text={getParagraphTextFromWebsiteContent(sectionContent, 'perk3')}
        />
      </div>
    </section>
  );
};

export default SectionMarkers;
