import Icon from 'src/components/Icon';
interface Props {
  text: string;
  icon: { component: React.FunctionComponent<React.SVGProps<SVGSVGElement>>; ariaLabelledby: string };
}

function MarkerPerk({ icon, text }: Props) {
  return (
    <div className="flex flex-row items-center mb-32">
      <Icon component={icon.component} ariaLabelledby={icon.ariaLabelledby} size={32} fill="#222222" />
      <div className="flex justify-center ml-24">
        <p className="text-dark">{text}</p>
      </div>
    </div>
  );
}

export default MarkerPerk;
