import { Typography } from '@remarkable/ark-web';
import Icon from 'src/components/Icon';
import { IconsListType } from 'src/views/MainPage/Typings';
interface Props {
  data: IconsListType[];
}

function Icons({ data }: Props) {
  return (
    <ul
      className={
        'my-0 mx-[60px] hidden list-none p-0 text-16 text-grayscale-gray-50 md:col-span-2 md:col-start-1 md:flex md:justify-between md:text-center xl:col-start-2 [@media(min-width:1920px)]:col-start-1'
      }
    >
      {data.map(({ icon, text }: IconsListType, idx) => {
        return (
          <li key={idx} className={'flex min-w-[80px] flex-col items-center md:mt-48'}>
            <Icon component={icon.component} size={32} color={'transparent'} ariaLabelledby={icon.ariaLabelledby} />
            <Typography variant="body-md-book" className="mt-16 text-grayscale-gray-50 md:text-12 lg:text-16">
              {text}
            </Typography>
          </li>
        );
      })}
    </ul>
  );
}

export default Icons;
