import cx from 'classnames';
import React from 'react';
import {
  buildSanityBkgImageSet,
  sanityImgObjectFromGenericImage,
} from 'src/components/Image/SanityImage/sanityImageHelpers';
import SanityResponsiveImage from 'src/components/Image/SanityResponsiveImage';
import { extractImageBySelector } from 'src/helpers/sanityHelpers';
import { BP } from 'src/helpers/tailwindBreakpointsHelpers';
import { useActiveBreakpoint } from 'src/hooks/useActiveBreakpoint';
import { useExperimentContext } from 'src/services/experiments/ExperimentContext';
import { ExperimentVariant } from 'src/services/experiments/optimize';
import { WebsiteSectionContentWithImages } from 'src/typings/websiteContent';
import { isDesktop } from 'src/utils/isDesktop';
import { isTablet } from 'src/utils/isTablet';

interface SectionRmVsTablets {
  sectionContent: WebsiteSectionContentWithImages;
}

const RmVsTablets: React.FC<SectionRmVsTablets> = ({ sectionContent }) => {
  const currentBp: BP | null = useActiveBreakpoint();
  const isTabletOrDesktop = isTablet(currentBp) || isDesktop(currentBp);
  const { experimentVariantId } = useExperimentContext();

  const image3 = extractImageBySelector(sectionContent, 'image3');
  const image4 = extractImageBySelector(sectionContent, 'image4');
  const image5 = extractImageBySelector(sectionContent, 'image5');

  const backgroundImageCss = buildSanityBkgImageSet(
    sanityImgObjectFromGenericImage(image3),
    {},
    'linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(0, 0, 0, 1) 94%)'
  );

  const Row = ({
    children,
    titel,
  }: {
    children: ReturnType<typeof RowBox> | ReturnType<typeof RowBox>[];
    titel: string;
  }) => (
    <div className={'flex flex-col items-center py-0 px-12'}>
      <h2 className={'text-[30px] mt-[30px] mx-0 mb-16 md:mb-40 text-[#a0a0a0] md:text-[55px] md:mt-[60px]'}>
        {titel}
      </h2>
      {children}
    </div>
  );

  const RowBox = ({ left, right, show = true }: { left: string; right: string; show?: boolean }) => {
    if (!show) return null;
    return (
      <div className={cx(['group', 'flex flex-row justify-evenly w-full'])}>
        <div
          className={cx([
            'flex justify-center items-center bg-[#e1deda] w-full mt-0 mr-12 mb-[10px] ml-12 text-14 text-center py-[26px] px-[17px] max-w-[500px] md:text-18',
            'group-[&:last-of-type]:mb-20 md:group-[&:last-of-type]:mb-40',
            'group-[&:first-of-type]:mt-20 md:group-[&:first-of-type]:mt-0',
          ])}
        >
          {left}
        </div>
        <div className={'z-0 border-l border-[1px] border-solid border-[#a0a0a0] opacity-[.38]'} />
        <div
          className={cx([
            'flex justify-center items-center bg-[#e1deda] w-full mt-0 mr-12 mb-[10px] ml-12 text-14 text-center py-[26px] px-[17px] max-w-[500px] md:text-18',
            'group-[&:last-of-type]:mb-20 md:group-[&:last-of-type]:mb-40',
            'group-[&:first-of-type]:mt-20 md:group-[&:first-of-type]:mt-0',
          ])}
        >
          {right}
        </div>
      </div>
    );
  };
  return (
    <section className={'bg-[#f0f0f0] pb-[50px] md:pt-[30px] md:pb-[100px]'} id="reMarkable_vs_other_tablets">
      <div className={'flex flex-col max-w-[1360px] my-0 mx-auto p-0'}>
        <div
          className={'h-[150px] bg-[length:130%] md:h-[408px]'}
          style={{ ...backgroundImageCss, backgroundPosition: 'center' }}
        />
        <div className={'flex items-start relative h-[160px] md:h-[255px]'}>
          <div className={'flex flex-col items-center absolute left-0 bottom-0 w-1/2 h-auto pb-20 md:pb-[50px]'}>
            <div className={'flex justify-center w-full py-0 px-[30px]'}>
              <SanityResponsiveImage
                imgDefault={{
                  sanityImgObject: { ...sanityImgObjectFromGenericImage(image4) },
                  fitMode: 'cover',
                }}
                imgSizeSet={[
                  { bp: 320 /* xs */, sanityImgOptions: { width: 110 } },
                  { bp: 768 /* md */, sanityImgOptions: { width: 215 } },
                ]}
                pictureClassName={'w-full max-w-[110px] md:max-w-[215px]'}
              />
            </div>
            <h2 className="text-[20px] mt-20 mx-0 mb-0 md:mt-[50px] md:text--[50px] lg:text-[60px]">Other tablets</h2>
          </div>
          <div
            className={
              'z-0 border-l border-[1px] border-solid border-[#a0a0a0] opacity-[.38] left-[calc(50%-1px)] h-full relative'
            }
          />
          <div
            className={
              'mt-[32px] mx-auto mb-0 h-[47px] w-[47px] relative rounded-[50%] bg-grayscale-gray-800 text-grayscale-gray-50 flex justify-center items-center text-center p-[26px] md:mt-[50px] md:h-[110px] md:w-[110px]'
            }
          >
            <h2 className="relative top-[-2px] md:top-[-4px] text-[35px] md:text-[72px]">vs</h2>
          </div>
          <div className={'flex flex-col items-center absolute right-0 bottom-0 w-1/2 h-auto pb-20 md:pb-[50px]'}>
            <div className={'flex justify-center w-full py-0 px-[30px]'}>
              <SanityResponsiveImage
                imgDefault={{
                  sanityImgObject: { ...sanityImgObjectFromGenericImage(image5) },
                  fitMode: 'cover',
                }}
                imgSizeSet={[
                  { bp: 320 /* xs */, sanityImgOptions: { width: 110 } },
                  { bp: 768 /* md */, sanityImgOptions: { width: 215 } },
                ]}
                pictureClassName={'w-full max-w-[110px] md:max-w-[215px]'}
              />
            </div>
            <h2 className="text-[20px] mt-20 mx-0 mb-0 md:mt-[50px] md:text--[50px] lg:text-[60px]">reMarkable 2</h2>
          </div>
        </div>
        <Row titel="Display">
          <RowBox left="Slippery surface" right="Paper-like surface" />
          <RowBox left="Backlit, reflective surface" right="No glare or backlight, sunlight readable" />
        </Row>

        {experimentVariantId === ExperimentVariant.A ? (
          <>
            <Row titel="Functionality">
              <RowBox left="Everything your computer does" right="Writing, typing, visualizing, and reading" />
              <RowBox left="Social media, email, and notifications" right="No notifications or distractions" />
              <RowBox left="Open internet access" right="Sync notes and send documents via Wi-Fi only" />
              <RowBox show={isTabletOrDesktop} left="Needs recharging often" right="2 weeks of battery life" />
            </Row>
            <Row titel="Purpose">
              <RowBox left="Replacing your laptop" right="Replacing paper and improving your workflow" />
            </Row>
          </>
        ) : (
          <>
            <Row titel="Functionality">
              <RowBox left="Everything your computer does" right="Focused writing, typing, visualizing, and reading" />
              <RowBox left="Social media, email, and notifications" right="No notifications or distractions" />
              <RowBox left="Open internet access" right="Sync notes and send documents via Wi-Fi" />
              <RowBox left="Type and sketch separately" right="Combine typing and handwriting with Free Create" />
              <RowBox show={isTabletOrDesktop} left="Needs recharging often" right="2 weeks of battery life" />
            </Row>
            <Row titel="Purpose">
              <RowBox left="Replacing your laptop" right="Helping you focus" />
            </Row>
          </>
        )}
      </div>
    </section>
  );
};

export default RmVsTablets;
