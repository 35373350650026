import Icon from 'src/components/Icon';
import { IconsListType } from 'src/views/MainPage/Typings';

interface Props {
  data: IconsListType[];
}

const MobileExpandableMenu = ({ data }: Props) => {
  return (
    <ul className={'p-0 list-none text-grayscale-gray-50 z-[1]'}>
      {data.map(({ icon, text }: IconsListType, index) => {
        return (
          <li className={'pt-20 pr-0 pb-0 pl-[30px] whitespace-nowrap flex items-center text-16'} key={index}>
            <Icon
              component={icon.component}
              ariaLabelledby={icon.ariaLabelledby}
              color={'transparent'}
              size={32}
              width={26}
            />
            <div className="ml-16">{text}</div>
          </li>
        );
      })}
    </ul>
  );
};

export default MobileExpandableMenu;
