import { useSelector } from 'react-redux';
import Cart from 'src/components/Cart';
import Layout from 'src/components/Layout';
import {
  MarketingOptInExitIntent,
  MarketingOptInOnScroll,
  MarketingOptInSection,
  SectionType,
} from 'src/components/MarketingOptIn';
import { Menu } from 'src/components/Menu';
import { CenterPromotionBanner } from 'src/components/PromotionBanner/CenterPromotionBanner';
import { isValidPromo, isValidPromoForCountry } from 'src/components/PromotionBanner/promotionUtils';
import { TopPromotionBanner } from 'src/components/PromotionBanner/TopPromotionBanner';
import RmHead from 'src/components/RmHead';
import SeoHead from 'src/components/SeoHead';
import NoShippingModal from 'src/helper-components/NoShippingModal';
import NotificationBar from 'src/helper-components/PromotionBanner/NotificationBar';
import { rm110ReferralDiscountSelector } from 'src/helpers/rm110ReferralDiscount';
import { findBySelector, getParagraphTextFromSection, getSectionContentBySelectorId } from 'src/helpers/sanityHelpers';
import { BP } from 'src/helpers/tailwindBreakpointsHelpers';
import { useReduxCart } from 'src/hooks/cart/useCart';
import { useActiveBreakpoint } from 'src/hooks/useActiveBreakpoint';
import { useApplyPromotionCode } from 'src/hooks/useApplyPromotionCode';
import { useApplyReferralCode } from 'src/hooks/useApplyReferralCode';
import { useConnectPromotion } from 'src/hooks/useConnectPromotion';
import { useCountryData } from 'src/hooks/useCountryData';
import { usePromotionBannerData } from 'src/hooks/usePromotionBannerData';
import { useVisibleCartOnRouteParam } from 'src/hooks/useVisibleCartOnRouteParam';
import { EmergencyBanner } from 'src/queries/groq/emergencyBanner';
import { PromotionBanners } from 'src/queries/groq/promotionBanners';
import { PressPublicationsData } from 'src/queries/groq/publicationsData';
import { WebsiteContentWithTextsAndImages } from 'src/queries/groq/sanityWebsiteContent';
import { State } from 'src/redux/reducers';
import { WebsiteSectionContentWithImages, WebsiteSectionContentWithTexts } from 'src/typings/websiteContent';
import { isDesktop } from 'src/utils/isDesktop';
import { isMobile } from 'src/utils/isMobile';
import { isTablet } from 'src/utils/isTablet';
import ScreenShare from 'src/views/MainPage/sections/ScreenShare';
import Workflow from 'src/views/MainPage/sections/Workflow';
import Connect from './sections/Connect';
import Folios from './sections/Folios';
import { HeroTypeFolio } from './sections/HeroTypeFolio';
import Markers from './sections/Markers';
import PaperFeel from './sections/PaperFeel';
import PaperThinSection from './sections/PaperThinTablet';
import { PressReviews } from './sections/Press/PressReviews';
import Read from './sections/Read';
import RemarkableForTeams from './sections/RemarkableForTeams';
import SectionRmVsTablets from './sections/SectionRmVsTablets-v2';
import TechnicalSpecifications from './sections/Technical-specifications';
import { TypeFolioSummary } from './sections/TypeFolioSummary';
import { TypeFolioTF } from './sections/TypeFolioTF';
import Visualization from './sections/Visualization';

export interface MainPageViewProps {
  data: {
    sectionData: WebsiteContentWithTextsAndImages;
    promotionBanners: PromotionBanners;
    emergencyBanner: EmergencyBanner;
    pressPublicationsData: PressPublicationsData;
  };
}

export type DataTheme = 'on-dark' | 'on-light';
export type OnCtaClicked = () => void;

export function MainPage({ data }: MainPageViewProps) {
  const { country, countryHasBeenSet } = useCountryData();
  const currentBp: BP | null = useActiveBreakpoint();
  const isMobileOrTablet = isMobile(currentBp) || isTablet(currentBp);

  const cart = useReduxCart();
  useApplyReferralCode();
  useApplyPromotionCode(cart);

  const maybeRM110Discount = useSelector(rm110ReferralDiscountSelector);

  const { sectionData, promotionBanners } = data;

  const { promotionData, promoEndDate, bannerOptions } = usePromotionBannerData(
    country,
    promotionBanners,
    countryHasBeenSet
  );

  // Check if notification is active (either emergency-banner or referral)
  const dismissed = useSelector((state: State) => state.notificationbar.dismissed);
  const activated = useSelector((state: State) => state.notificationbar.activated);
  const referralCode = useSelector((state: State) => state.store2.referralCode);

  const connectPromotionDiscount = useConnectPromotion();
  const activatedNotification = (!dismissed && activated) || referralCode || connectPromotionDiscount?.promotionCode;

  const metaImageSectionContent = getSectionContentBySelectorId(
    sectionData,
    'metaData'
  ) as WebsiteSectionContentWithImages & WebsiteSectionContentWithTexts;

  useVisibleCartOnRouteParam();

  const showCart = true;
  const activePromo = !!(
    !activatedNotification &&
    promotionData &&
    bannerOptions &&
    promoEndDate &&
    isValidPromo(promotionData, promoEndDate, bannerOptions.mainPageTop) &&
    isValidPromoForCountry(promotionData.countries, country)
  );

  return (
    <Layout>
      {metaImageSectionContent && <RmHead />}
      <SeoHead
        title="Home"
        ogImage={{
          url: findBySelector(metaImageSectionContent.images, 'og:image')?.image.asset.url ?? '',
          type: getParagraphTextFromSection(metaImageSectionContent, 'og:image:type'),
          width: parseInt(getParagraphTextFromSection(metaImageSectionContent, 'og:image:width'), 10),
          height: parseInt(getParagraphTextFromSection(metaImageSectionContent, 'og:image:height'), 10),
          alt: getParagraphTextFromSection(metaImageSectionContent, 'og:image:alt'),
        }}
      />
      <div className="bg-[#F1EEEA] min-[650px]:bg-[#F4F0ED] min-h-[1000px]">
        <Menu
          backgroundColor={
            !!(activatedNotification && !dismissed) || activePromo
              ? 'bg-[#F1EEEA] min-[650px]:bg-[#F4F0ED]'
              : 'bg-[#F2EEEB] min-[650px]:bg-[#F4F0ED]'
          }
          showCart={true}
          isDarkOnTop={false}
          hideOnScroll={true}
          alternateStyle={true}
          activeNotification={!!(activatedNotification && !dismissed) || activePromo}
        >
          {activatedNotification && <NotificationBar emergencyBanner={data.emergencyBanner} addBodyPadding />}
          {!activatedNotification &&
            promotionData &&
            bannerOptions &&
            promoEndDate &&
            isValidPromo(promotionData, promoEndDate, bannerOptions.mainPageTop) &&
            isValidPromoForCountry(promotionData.countries, country) && (
              <TopPromotionBanner {...bannerOptions.mainPageTop} endDate={new Date(promoEndDate)} />
            )}
        </Menu>

        <main className="overflow-hidden">
          <HeroTypeFolio
            sectionContent={
              getSectionContentBySelectorId(sectionData, 'heroSeabird') as WebsiteSectionContentWithTexts &
                WebsiteSectionContentWithImages
            }
            referral={maybeRM110Discount}
            dataTheme={'on-light' as DataTheme}
            activeNotification={!!(activatedNotification && !dismissed) || activePromo}
          />

          {promotionData &&
            bannerOptions &&
            promoEndDate &&
            isValidPromo(promotionData, promoEndDate, bannerOptions.mainPageCenter) &&
            isValidPromoForCountry(promotionData.countries, country) && (
              <CenterPromotionBanner {...bannerOptions.mainPageCenter} endDate={new Date(promoEndDate)} />
            )}

          <TypeFolioSummary
            sectionContent={
              getSectionContentBySelectorId(sectionData, 'seabirdSummaryModule') as WebsiteSectionContentWithTexts &
                WebsiteSectionContentWithImages
            }
          />

          <PressReviews country={country} pressPublications={data.pressPublicationsData} />

          {/* ---------- Above the fold ------------*/}

          <Workflow
            sectionContent={
              getSectionContentBySelectorId(sectionData, 'writeSection') as WebsiteSectionContentWithTexts &
                WebsiteSectionContentWithImages
            }
          />

          <TypeFolioTF
            sectionContent={
              getSectionContentBySelectorId(sectionData, 'seabirdModule') as WebsiteSectionContentWithTexts &
                WebsiteSectionContentWithImages
            }
          />

          <MarketingOptInOnScroll></MarketingOptInOnScroll>

          <Read
            sectionContent={
              getSectionContentBySelectorId(sectionData, 'readSection') as WebsiteSectionContentWithTexts &
                WebsiteSectionContentWithImages
            }
          />

          <Connect
            sectionContent={
              getSectionContentBySelectorId(sectionData, 'subscribeToConnectModule') as WebsiteSectionContentWithTexts &
                WebsiteSectionContentWithImages
            }
          />

          <Visualization
            sectionContent={
              getSectionContentBySelectorId(sectionData, 'sectionVisualize') as WebsiteSectionContentWithTexts &
                WebsiteSectionContentWithImages
            }
          />

          <ScreenShare
            sectionContent={
              getSectionContentBySelectorId(sectionData, 'screenShare') as WebsiteSectionContentWithTexts &
                WebsiteSectionContentWithImages
            }
          />

          <PaperFeel
            sectionContent={
              getSectionContentBySelectorId(sectionData, 'paperFeelSection') as WebsiteSectionContentWithTexts &
                WebsiteSectionContentWithImages
            }
            isDesktop={isDesktop(currentBp)}
          />

          <span>
            <MarketingOptInSection type={SectionType.MainPage} />

            <SectionRmVsTablets
              sectionContent={
                getSectionContentBySelectorId(sectionData, 'rMVsTabletSection') as WebsiteSectionContentWithImages
              }
            />
          </span>

          <PaperThinSection
            sectionContent={
              getSectionContentBySelectorId(sectionData, 'paperThinSection') as WebsiteSectionContentWithTexts &
                WebsiteSectionContentWithImages
            }
          />

          <Folios
            sectionContent={
              getSectionContentBySelectorId(sectionData, 'foliosSection') as WebsiteSectionContentWithTexts &
                WebsiteSectionContentWithImages
            }
          />

          <Markers sectionContent={sectionData} />

          <RemarkableForTeams
            sectionContent={
              getSectionContentBySelectorId(
                sectionData,
                'reMarkableForTeamsSection'
              ) as WebsiteSectionContentWithTexts & WebsiteSectionContentWithImages
            }
            isMobile={isMobileOrTablet}
          />

          <TechnicalSpecifications
            sectionContent={
              getSectionContentBySelectorId(sectionData, 'techSpecSection') as WebsiteSectionContentWithTexts
            }
          />

          <NoShippingModal />
          {showCart && <Cart />}

          <MarketingOptInExitIntent />
        </main>
      </div>
    </Layout>
  );
}

export default MainPage;
