import cx from 'classnames';
import Icon from 'src/components/Icon';

export interface InterfaceTab {
  activeTab: string;
  label: string;
  onTabClick: (label: string) => void;
  icon?: { component: React.FunctionComponent<React.SVGProps<SVGSVGElement>>; ariaLabelledby: string };
}

const TabItem = (props: InterfaceTab) => {
  const { label, onTabClick, activeTab, icon } = props;

  function tabClicked() {
    onTabClick(label);
  }

  return (
    <li
      className={cx(
        'cursor-pointer list-none flex flex-col pb-8 items-center justify-center text-center opacity-70 border-b-transparent mb-8',
        {
          'font-bold border-b-brand-orange-400 border-b-medium !opacity-100 border-solid border-x-0 border-t-0':
            activeTab === label,
        }
      )}
      onClick={tabClicked}
      role="button"
      aria-pressed={activeTab === label}
    >
      {icon && (
        <Icon
          component={icon.component}
          ariaLabelledby={icon.ariaLabelledby}
          size={32}
          fill="#EFEFEF"
          className="mb-8"
        />
      )}
      {label}
    </li>
  );
};

export default TabItem;
