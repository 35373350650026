import { getSanityImageResponsive, Image, Typography } from '@remarkable/ark-web';
import React from 'react';
import { extractGenericImageBySelector } from 'src/helpers/sanityHelpers';
import { WebsiteSectionContentWithImages, WebsiteSectionContentWithTexts } from 'src/typings/websiteContent';
import sanityClient from 'src/utils/sanityClient';
import { NotesOnDocument } from 'src/views/MainPage/sections/Read/components/NotesOnDocument';
import { ReadWebArticles } from 'src/views/MainPage/sections/Read/components/ReadWebArticles';

interface Props {
  sectionContent: WebsiteSectionContentWithTexts & WebsiteSectionContentWithImages;
}

const Read: React.FC<Props> = ({ sectionContent }) => {
  if (!sectionContent) return null;

  const headerImage = extractGenericImageBySelector(sectionContent, 'image1');

  return (
    <section
      data-theme="on-dark"
      className="bg-brand-blue-700 text-center pt-48 md:pt-[80px] lg:pt-[120px] md:px-24 lg:px-[72px] pb-32 sm:pb-64"
      id="Reading_on_reMarkable"
    >
      <div className="max-w-[1360px] mx-auto">
        <div className="px-24 mb-32 md:px-0 lg:px-32 lg:mb-48">
          <Typography
            as="h1"
            variant="responsive-heading-xl"
            className="heading md:px-64 lg:px-0 max-w-[640px] lg:max-w-none mx-auto"
          >
            {sectionContent.header}
          </Typography>

          <Typography
            variant="responsive-body-lg"
            className="m-0 hidden lg:block text-24 md:my-48 md:mx-[96px] lg:mb-[96px] lg:mx-auto max-w-[600px]"
          >
            {sectionContent.subHeader}
          </Typography>
        </div>

        <Image
          className="object-cover w-full"
          {...getSanityImageResponsive({
            alt: headerImage.alt ?? 'An eye-friendly reading experience',
            sanityClient: sanityClient,
            sanityImage: headerImage,
            options: { width: 375 },
          })}
        />

        <Typography
          variant="responsive-body-lg"
          className="m-0 lg:hidden mt-24 mb-40 max-w-[540px] px-24 sm:px-0 md:my-48 mx-auto"
        >
          {sectionContent.subHeader}
        </Typography>

        <div className="px-24 sm:px-[72px] lg:px-0 mt-48">
          <NotesOnDocument sectionContent={sectionContent} />
          <ReadWebArticles sectionContent={sectionContent} />
        </div>
      </div>
    </section>
  );
};

export default Read;
